import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  resend: {
    color: "#4596FF",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer"
  }
}));
