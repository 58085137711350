import React, { useEffect } from 'react'
import Navbar from '../Navbar'
import SideDrawer from '../SideDrawer'
import { Box } from '@mui/material'
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Auth/slice';
import SyncDataPopUp from '../SyncDataPopUp';
import { errorMessage } from 'app/containers/Dashboard/selector';
import LanguageDetector from '../LanguageDetector';
import storage from 'utils/storage';
import { useNavigate } from 'react-router-dom';

export default function Layout({
    children
}: { children: React.ReactNode}) {
    const auth = localStorage.getItem('sessionToken');
    const dispatch = useDispatch()
    const dashboardError = useSelector(errorMessage)
    const navigate = useNavigate()

    useEffect(()=>{
        if(auth){
            dispatch(actions.getUser({
                callback: () => onLogout()
            }))
        }
    },[auth, dispatch])

    const onLogout = async () => {
        await Promise.all([storage.clear("sessionToken"), storage.clear("currentProfileId")])
        dispatch(actions.resetUser())
        navigate("/auth/welcome")
    }


    if(!auth) {
        return <Navigate to="/auth/welcome" />
    }
    return (
        <LanguageDetector>
            <Box sx={{ display: 'flex', background: "#F5FAFF", height: "100vh", overflow: "clip" }}>
                <Navbar />
                <SideDrawer />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        padding: "34px 46px",
                        marginTop: "61px",
                        overflow: "auto",
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none'
                    }}
                >
                    {children}
                </Box>
                <SyncDataPopUp
                    open={dashboardError === 'USERS.ERRORS.MEASURE_DATA_NOT_FOUND'}
                />
            </Box>
        </LanguageDetector>
    )
}
