/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import ScrollToTop from 'app/components/ScrollToTop';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { selectDirection, themeActions } from '../styles/theme/slice';
import { AuthPage } from './containers/Auth';
import AllSet from './containers/Auth/allSet';
import LoginForm from './containers/Auth/LoginPage/form';
import { userAuthSaga } from './containers/Auth/saga';
import Signup from './containers/Auth/SignupPage/form';
import { reducer, sliceKey } from './containers/Auth/slice';
import Dashboard from './containers/Dashboard';
import { dashboardSaga } from './containers/Dashboard/saga';
import { dashboardSliceKey, reducer as dashboardReducer } from './containers/Dashboard/slice';
import Notifications from './containers/Notifications';
import { notificationSaga } from './containers/Notifications/saga';
import {
    notificationSliceKey, reducer as notificationReducer
} from './containers/Notifications/slice';
import Pages from './containers/pages';
import Settings from './containers/Settings';
import { settingsSaga } from './containers/Settings/saga';
import { reducer as settingsReducer, settingsSliceKey } from './containers/Settings/slice';
import { UnsupportedScreen } from './containers/UnsupportedScreen';
import Usage from './containers/Usage';
import { usageSaga } from './containers/Usage/saga';
import { reducer as usageReducer, usageSliceKey } from './containers/Usage/slice';
import Welcome from './containers/Auth/welcome';

const MIN_WIDTH = 1000;

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userAuthSaga });
  useInjectSaga({ key: settingsSliceKey, saga: settingsSaga });
  useInjectReducer({ key: settingsSliceKey, reducer: settingsReducer });
  useInjectReducer({ key: dashboardSliceKey, reducer: dashboardReducer });
  useInjectSaga({ key: dashboardSliceKey, saga: dashboardSaga });
  useInjectReducer({ key: usageSliceKey, reducer: usageReducer });
  useInjectSaga({ key: usageSliceKey, saga: usageSaga });
  useInjectReducer({ key: notificationSliceKey, reducer: notificationReducer });
  useInjectSaga({ key: notificationSliceKey, saga: notificationSaga });

  const direction = useSelector(selectDirection);

  const { width } = useWindowDimensions();

  const unsupportedScreen = width && width <= MIN_WIDTH;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Helmet titleTemplate="%s - Afcon" defaultTitle="Afcon">
        <meta name="description" content="A Afcon application" />
      </Helmet>
      <div dir={direction}>

        <Routes>
          <Route path="/pages/*" element={<Pages />} />
        </Routes>
        {unsupportedScreen ? (
          <UnsupportedScreen minWidth={MIN_WIDTH} />
        ) : (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/usage" element={<Usage />} />

            <Route path="/auth/*" element={<AuthPage />}>
              <Route path="login" index element={<LoginForm className="" />} />
              <Route path="signup" element={<Signup />} />
              <Route path="all-set" element={<AllSet />} />
              <Route path="welcome" element={<Welcome />} />
            </Route>
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}
