import { selectUser, selectUserProfiles } from 'app/containers/Auth/selectors';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectDirection, themeActions } from 'styles/theme/slice';
import { LoggerClass } from 'utils/logger';
import storage from 'utils/storage';

export default function LanguageDetector({
    children
}:{
    children: React.ReactNode
}) {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const userProfiles = useSelector(selectUserProfiles)
    const currentProfile = userProfiles?.find((profile: any) => profile.id === localStorage.getItem('currentProfileId'))
    const { i18n } = useTranslation();
    const direction = useSelector(selectDirection)

    useEffect(()=>{
        if(currentProfile?.language ?? user?.language){
            i18n.changeLanguage(currentProfile?.language?.toLowerCase() ?? user?.language?.toLowerCase())
            storage.set('i18nextLng', currentProfile?.language?.toLowerCase() ?? user?.language?.toLowerCase()).then(()=>{
                LoggerClass.log('=======', "Language set to storage");
            })
            const lang = currentProfile?.language?.toLowerCase() ?? user?.language?.toLowerCase()
            if(lang === "en"){
                dispatch(themeActions.toggleDirection('ltr'))
                return
            }
            dispatch(themeActions.toggleDirection('rtl'))
            return
        }
        storage.get('i18nextLng').then(lang => {
            if(lang){
                i18n.changeLanguage(lang)
                if(lang === 'en'){
                    dispatch(themeActions.toggleDirection('ltr'))
                    return
                }
                dispatch(themeActions.toggleDirection('rtl'))
            } else {
                storage.set('i18nextLng', 'en')
            }
        })
    },[currentProfile?.language, direction, dispatch, user?.language])

    return (
        <>
            {children}
        </>
    )
}
