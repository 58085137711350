const __DEV__ = process.env.NODE_ENV === "development"
export class LoggerClass {
  static log(...args) {
    __DEV__ && console.log(...args);
  }
  static warn(...args) {
    __DEV__ && console.warn(...args);
  }
  static info(...args) {
    __DEV__ &&
      console.info(
        '%c Custom Log:',
        'background: blue; color: white;',
        ...args,
      );
  }
}
