import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useState } from 'react'
import useStyles from './styles';

interface OptionsType {
    label: string;
    value: string;
}

interface DropdownProps {
    value: string;
    handleChange: (e: SelectChangeEvent<any>) => void;
    options?: OptionsType[]
}

export default function Dropdown({
    value,
    handleChange,
    options
}: DropdownProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const classes = useStyles();

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 174, border: "none" }}>
            <Select
                value={value}
                onChange={handleChange}
                displayEmpty
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    PaperProps: {
                        style: {
                            borderRadius: "0 0 10px 10px", // Dynamically set the border radius
                            background: "#ffffff",
                            color: "#4596FF",
                            textAlign: "center",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 400,
                        },
                    },
                }}
                sx={{
                    borderRadius: isMenuOpen ? "10px 10px 0 0" : "10px", // Dynamically set the border radius
                    background: "#4596FF",
                    height: "36px",
                    border: "none",
                    outline: "none",
                    color: "#fff",
                    '&:focus': {
                        backgroundColor: "#4596FF",
                        borderRadius: isMenuOpen ? "10px 10px 0 0" : "10px", // Dynamically set the border radius
                        border: "none",
                        outline: "none",
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: "none",
                    },
                    '& .MuiSelect-root': {
                        paddingRight: "0",
                        color: "#fff",
                        '&::before': {
                            borderColor: "#fff",
                        },
                    },
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                }}
                inputProps={{
                    sx: {
                        border: "none",
                    },
                    classes: {
                        icon: classes.icon,
                    },
                }}
                onOpen={handleMenuOpen}
                onClose={handleMenuClose}
            >
                {
                    options?.map(item=>{
                        return (
                            <MenuItem value={item?.value}>{item?.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}
