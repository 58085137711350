import {createSlice} from 'utils//@reduxjs/toolkit';

import {PayloadAction} from '@reduxjs/toolkit';

import {ErrorType} from 'types/ErrorResponse';
import {
  BarGraphData,
  ContainerState,
  ExportResponse,
  KEYS_OF_FILTER_OPTION,
  FILTER_TYPE,
  KEYS_OF_FILTER_TYPE,
  GraphTypeParams,
  ListDataResponse,
  Meta,
  OptionsList,
} from './types';
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  loading: false,
  error: null,
  listData: [],
  dailyBarGraphData: [],
  isDailyLoading: false,
  isWeeklyLoading: false,
  isMonthlyLoading: false,
  weeklyBarGraphData: [],
  monthlyBarGraphData: [],
  monthlyData: [],
  averageMonthConsumption: 0,
  dailyMean: 0,
  weeklyMean: 0,
  monthlyMean: 0,
  isMore: false,
  meta: {} as Meta,
  optionsList: [],
  exportResponse: {} as ExportResponse,
  downloadUrl: '',
  downloadUrlLoading: false,
};

const usageSlice = createSlice({
  name: 'usageState',
  initialState,
  reducers: {
    getOptionList(state) {
      state.loading = true;
      state.error = null;
      state.optionsList = [];
    },
    getOptionListSuccess(state, action: PayloadAction<OptionsList[]>) {
      state.loading = false;
      state.error = null;
      state.optionsList = action.payload;
    },

    getOptionListFiled(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.error = action.payload;
    },
    getBarGraphData(state, action?: PayloadAction<GraphTypeParams>) {
      state.loading = true;
      state.error = null;
      state.dailyBarGraphData = [];
      state.weeklyBarGraphData = [];
      state.monthlyBarGraphData = [];
    },
    getDailyBarGraphData(state, action: PayloadAction<GraphTypeParams>) {
      state.isDailyLoading = true;
      state.error = null;
      state.dailyBarGraphData = [];
    },
    getWeeklyBarGraphData(state, action: PayloadAction<GraphTypeParams>) {
      state.isWeeklyLoading = true;
      state.error = null;
      state.weeklyBarGraphData = [];
    },
    getMonthlyBarGraphData(state, action: PayloadAction<GraphTypeParams>) {
      state.isMonthlyLoading = true;
      state.error = null;
      state.monthlyBarGraphData = [];
    },
    setDailyBarGraphData(state, action: PayloadAction<BarGraphData>) {
      state.error = null;
      state.dailyBarGraphData = action.payload.data;
      state.dailyMean = action.payload.mean;
      if (state.dailyBarGraphData.length) {
        state.dailyBarGraphData[state.dailyBarGraphData.length - 1].isCurrent = true;
      }
      state.isDailyLoading = false;
    },
    setWeeklyBarGraphData(state, action: PayloadAction<BarGraphData>) {
      state.error = null;
      state.weeklyBarGraphData = action.payload.data;
      state.weeklyMean = action.payload.mean;
      if (state.weeklyBarGraphData.length) {
        state.weeklyBarGraphData[state.weeklyBarGraphData.length - 1].isCurrent = true;
      }
      state.isWeeklyLoading = false;
    },
    setMonthlyBarGraphData(state, action: PayloadAction<BarGraphData>) {
      state.error = null;
      state.monthlyBarGraphData = action.payload.data;
      state.monthlyMean = action.payload.mean;
      if (state.monthlyBarGraphData.length) {
        state.monthlyBarGraphData[state.monthlyBarGraphData.length - 1].isCurrent = true;
      }
      state.isMonthlyLoading = false;
    },
    // getUsageBarGraphDataSuccess(state, action: PayloadAction<BarGraphData>) {
    //   state.loading = false;
    //   state.error = null;
    //   state.mean = action.payload.mean;
    //   state.filterBarGraphData = action.payload.data;
    //   if (state.filterBarGraphData.length) {
    //     state.filterBarGraphData[
    //       state.filterBarGraphData.length - 1
    //     ].isCurrent = true;
    //   }
    // },

    getUsageBarGraphDataFailed(state, action: PayloadAction<ErrorType>) {
      state.isDailyLoading = false;
      state.isWeeklyLoading = false;
      state.isMonthlyLoading = false;
      state.error = action.payload;
    },
    getListData(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    getListDataSuccess(state, action: PayloadAction<ListDataResponse>) {
      state.loading = false;
      action.payload.meta.currentPage === 1
        ? (state.listData = action.payload.items)
        : (state.listData = [...state.listData, ...action.payload.items]);
      state.meta = action.payload.meta;
    },

    getListDataFailed(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.isMore = false;
      state.error = action.payload;
    },
    resetListData(state) {
      state.listData = [];
    },
    getListDataDownload(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    getListDataDownloadSuccess(state, action: PayloadAction<string>) {
      state.downloadUrl = action?.payload;
      state.loading = false;
    },
    getListDataDownloadFailed(state, action: PayloadAction<ErrorType>) {
      state.downloadUrl = '';
      state.loading = false;
      state.error = action.payload;
    },
    resetListDownloadUrl(state) {
      state.downloadUrl = '';
    }
  },
});

export const {actions, reducer, name: usageSliceKey} = usageSlice;
