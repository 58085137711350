import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Input, Typography } from '@mui/material';
import closeIcon from 'assets/ic_close.svg'
import SingleDatePicker from '../DatePicker';
import dayjs from 'dayjs'
import CustomButton from '../Button';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface NotificationExceedingProps {
    open: boolean;
    handleClose: () => void;
    onSubmit?: (v:{fromDate: Date, toDate: Date, limit: string}) => void;
}

export default function NotificationExceeding({
    open,
    handleClose,
    onSubmit
}:NotificationExceedingProps) {
    const [fromDate, setFromDate] = useState(dayjs()?.toDate());
    const [toDate, setToDate] = useState(dayjs().add(1, 'day')?.toDate());
    const [limit, setLimit] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const onValueChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        // Remove non-digit characters
        const digitsOnly = value.replace(/[^0-9.]/g, '');
        // Limit length to 9 characters
        const truncated = digitsOnly.slice(0, 9);
        // Ensure decimals have at most 2 decimal places
        const decimalIndex = truncated.indexOf('.');
        if (decimalIndex !== -1 && decimalIndex < truncated.length - 3) {
            const integerPart = truncated.slice(0, decimalIndex + 1);
            const decimalPart = truncated.slice(decimalIndex + 1, decimalIndex + 3);
            setLimit(integerPart + decimalPart);
        } else {
            setLimit(truncated);
        }
    };

    useEffect(() => {
        if (fromDate > toDate) {
            setError(t('SETTINGS.ERRORS.INVALID_DATE_RANGE'));
            return
        }
        setError('');
    }, [fromDate, toDate]);

    const onSave = () => {
        onSubmit?.({fromDate, toDate, limit});
        setLimit('');
    }

    const onClose = () => {
        setFromDate(dayjs()?.toDate());
        setToDate(dayjs().add(1, 'day')?.toDate());
        setLimit('');
        handleClose()
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="sm"
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: "20px",
                    border: "0.5px solid #FFF",
                    background: "#FFF",
                    padding: "37px",
                    width: "100%",
                    zIndex: "3",
                }
            }}
        >
            <Box
                sx={{
                    marginBottom:"17px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <Typography
                    sx={{
                        color: "#050415",
                        fontFamily: "Roboto",
                        fontWeight: 700,
                        fontSize: "14px"
                    }}
                >
                    {t("SETTINGS.YOUR_VACATION_MSG")}
                </Typography>
                <CustomButton
                    btnStyle={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent:"center",
                        alignItems: "center",
                        backgroundColor: "rgba(69, 150, 255, 0.40)",
                        minWidth: "0px",
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                    }}
                    onClick={onClose}
                >
                    <img alt="close" src={closeIcon} width="16px" height="16px" />
                </CustomButton>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    padding: "16px 0",
                    borderBottom: "1px solid #E9E9EA",
                    marginBottom: "16px"
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            color: "#071639",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                            marginBotton: "6px"
                        }}
                    >
                        {t("SETTINGS.FROM_DATE")}
                    </Typography>
                    <SingleDatePicker
                        selected={fromDate}
                        handleDateSelection={(date)=>setFromDate(date)}
                    />
                    {
                        error &&
                        <Typography
                            sx={{
                                color: "#FF0000",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                fontWeight: 500,
                            }}
                        >
                            {error}
                        </Typography>
                    }
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: "#071639",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                            marginBotton: "6px"
                        }}
                    >
                        {t("SETTINGS.TO_DATE")}
                    </Typography>
                    <SingleDatePicker
                        selected={toDate}
                        handleDateSelection={(date)=>setToDate(date)}
                        minDate={fromDate}
                    />
                </Box>
            </Box>
            <DialogContent
                sx={{
                    padding: "0px 0px 20px"
                }}
            >
                <Box
                    width="100%"
                >
                    <Typography
                        sx={{
                            color: "#050415",
                            fontFamily: "Roboto",
                            fontWeight: 700,
                            fontSize: "10px"
                        }}
                    >
                        {t(`SETTINGS.EXCEEDING_DIALOG_DESC`)}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: 0
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        sx={{
                            color: "#050415",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 600,
                        }}
                    >
                        {t('SETTINGS.EDIT_LABEL')}
                    </Typography>
                    <Input
                        sx={{
                            borderRadius: "8px",
                            border: "1px solid #E9E9EA",
                            background: "#FFF",
                            height: "46px",
                            padding: "13px 4px",
                            color: "#161616",
                            fontFamily: "Roboto",
                            fontSize: "20px",
                            fontWeight: 500,
                            width: "30%"
                        }}
                        disableUnderline
                        value={limit}
                        onChange={onValueChange}
                    />
                </Box>
            </DialogActions>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: '16px',
                }}
            >
                <CustomButton
                    onClick={onSave}
                    btnStyle={{
                        borderRadius: "25px",
                        width: "fit-content",
                        boxShadow: "0px 4px 10px 0px rgba(154, 166, 177, 0.30)",
                        fontFamily: "Roboto",
                        color: "#fff",
                        minWidth: "100px",
                        height: "auto"
                    }}
                    disabled={!limit}
                >
                    {t("BUTTONS.OK")}
                </CustomButton>
            </Box>
        </Dialog>
    );
}