/**
 * Homepage selectors
 */

import {RootState} from '../../../types';

import {createSelector} from '@reduxjs/toolkit';
import {initialState} from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.usageState || initialState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectListData = createSelector([selectDomain], state => state.listData);
export const error = createSelector([selectDomain], state => state.error);

export const selectDailyBarGraphData = createSelector(
  [selectDomain],
  state => state.dailyBarGraphData,
);

export const selectIsDailyLoading = createSelector(
  [selectDomain],
  state => state.isDailyLoading,
);

export const selectWeeklyBarGraphData = createSelector(
  [selectDomain],
  state => state.weeklyBarGraphData,
);

export const selectIsWeeklyLoading = createSelector(
  [selectDomain],
  state => state.isWeeklyLoading,
);

export const selectMonthlyBarGraphData = createSelector(
  [selectDomain],
  state => state.monthlyBarGraphData,
);

export const selectDailyMean = createSelector(
  [selectDomain],
  state => state.dailyMean,
);

export const selectWeeklyMean = createSelector(
  [selectDomain],
  state => state.weeklyMean,
);

export const selectMonthlyMean = createSelector(
  [selectDomain],
  state => state.monthlyMean,
);

export const selectIsMonthlyLoading = createSelector(
  [selectDomain],
  state => state.isMonthlyLoading,
);

export const filterMonthlyBarGraphData = createSelector(
  [selectDomain],
  state => state.monthlyData,
);

export const averageMonthConsumptionData = createSelector(
  [selectDomain],
  state => state.averageMonthConsumption,
);

export const myMetaInfo = createSelector([selectDomain], state => state.meta);

export const isMoreLoading = createSelector(
  [selectDomain],
  state => state.isMore,
);

export const selectDownloadPopupOpenState = createSelector(
  [selectDomain],
  state => {
    return {
      downloadUrlLoading: state.downloadUrlLoading,
      downloadUrl: state.downloadUrl,
    };
  },
);

export const selectDownloadUrl = createSelector(
  [selectDomain],
  state => state.downloadUrl,
);

// export const mean = createSelector([selectDomain], state => state.mean);

export const filterOption = createSelector(
  [selectDomain],
  state => state.optionsList,
);

export const exportResponse = createSelector(
  [selectDomain],
  state => state.exportResponse,
);
