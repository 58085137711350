import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Typography } from '@mui/material';
import closeIcon from 'assets/ic_close.svg'
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface NotificationExceedingProps {
    open,
    handleClose
}

export default function NotificationTip({
    open,
    handleClose,
}:NotificationExceedingProps) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    border: "0.5px solid #FFF",
                    background: "#FFF",
                    padding: "16px 37px",
                    minWidth: "350px",
                    position: "relative",
                    borderWidth: 2,
                    borderColor: "#4596FF",
                    borderRadius: "7px",
                    maxWidth: "350px"
                }
            }}
        >
            <Box
                sx={{
                    maxWidth: "90%"
                }}
            >
                <Typography
                    sx={{
                        color: "#050415",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: '400',
                        textAlign: 'left'
                    }}
                >
                    {t("SETTINGS.NOTIFICATION_TIP")}
                </Typography>
                <Button
                    sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent:"center",
                        alignItems: "center",
                        backgroundColor: "rgba(69, 150, 255, 0.40)",
                        minWidth: "0px",
                        position: "absolute",
                        top: "16px",
                        right: "16px"
                    }}
                    onClick={handleClose}
                >
                    <img alt="close" src={closeIcon} width="16px" height="16px" />
                </Button>
            </Box>
        </Dialog>
    );
}