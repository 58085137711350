import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TermsOfUsePage from './termOfUse';
import LanguageDetector from 'app/components/LanguageDetector';

const Pages = () => {
  return (
    <Routes>
      <Route
        path="/terms-of-use"
        element={
          <LanguageDetector>
            <TermsOfUsePage />
          </LanguageDetector>
        }
      />
    </Routes>
  );
};

export default Pages;
