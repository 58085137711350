import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: "#FEFEFE",
        boxShadow: "0px 4px 4px 0px rgba(217, 217, 217, 0.10)"
    },
    name: {
        color: "#050415",
        textAlign: "right",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 600
    },
    address: {
        color: "#999",
        textAlign: "right",
        fontFamily: "Open Sans",
        fontSize: "10px",
        fontWeight: 600,
    }
}));
