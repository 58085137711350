import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from 'app/containers/Dashboard/slice';
import storage from 'utils/storage';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TIMEOUT = 20;

export default function SyncDataPopUp({
    open=false
}) {
    const { t } = useTranslation()
    const [countDown, setCountDown] = useState(20);
    const dispatch = useDispatch();
    const theme = useTheme();

    const reloadData = () => {
        storage.get('currentProfileId').then((id)=>{
            dispatch(actions.getResources(id as string))
        })
    };

    useEffect(() => {
      if (open) {
        const interval = setInterval(() => {
          setCountDown(c => {
            const next = c - 1;
            if (c <= 0) {
              reloadData();
              return TIMEOUT;
            }
            return next;
          });
        }, 1000);
        return () => clearInterval(interval);
      }
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{}}
            PaperProps={{
                sx: {
                    borderRadius: "20px",
                    border: "0.5px solid #FFF",
                    background: "#FFF",
                    padding: "28px 37px",
                    minWidth: "350px",
                    position: "relative"
                }
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    direction: theme.direction,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 22,
                        fontWeight: '600',
                        color: '#AAACB7',
                        textAlign: 'center',
                    }}
                >
                    {t('DATA_SYNC.TITLE')}
                </Typography>
                <Typography
                    sx={{
                        marginTop: "20px",
                        fontSize: 16,
                        fontWeight: '400',
                        color: '#AAACB7',
                        textAlign: 'center',
                    }}
                >
                    {t('DATA_SYNC.DESCRIPTION')}
                </Typography>
                <Typography
                    sx={{
                        marginTop: "20px",
                        fontSize: 14,
                        fontWeight: '400',
                        color: '#AAACB7',
                        textAlign: 'center',
                    }}
                >
                    {countDown}
                </Typography>
            </Box>
        </Dialog>
    )
}
