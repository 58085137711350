import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    btn: {
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(154, 166, 177, 0.30)",
        height: "56px"
    }
}));
