/**
 * Homepage selectors
 */

import {RootState} from '../../../types';

import {createSelector} from '@reduxjs/toolkit';
import {initialState} from './slice';
import {initialState as authInitialState} from '../Auth/slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) =>
  state?.dashboardState || initialState;

const selectAuthDomain = (state: RootState) =>
  state?.authState || authInitialState;

export const selectLoading = createSelector([selectDomain], state => state.loading);

export const selectDashboardData = createSelector(
  [selectDomain],
  state => state.dashboardData,
);
// export const selectBarGraphData = createSelector(
//   [selectDomain],
//   state => state.barGraphData,
// );

export const selectMonthlyData = createSelector(
  [selectDomain],
  state => state.monthlyBarChartData,
);

export const selectYearlyData = createSelector(
  [selectDomain],
  state => state.yearlyBarChartData,
);

export const selectMonthlyMean = createSelector(
  [selectDomain],
  state => state.meanForMonthly,
);

export const selectYearlyMean = createSelector(
  [selectDomain],
  state => state.meanForYearly,
);

export const errorMessage = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectWaterIdErrorMessage = createSelector(
  [selectDomain],
  state => state.addWaterIdError,
);

// export const mean = createSelector([selectDomain], state => state.mean);

export const selectAddWaterIdResponse = createSelector(
  [selectDomain],
  state => state.waterIdResponse,
);

export const selectUserProfiles = createSelector(
  [selectAuthDomain],
  state => state.user?.userProfiles,
);

export const selectIsAddingWaterId = createSelector(
  [selectDomain],
  state => state.isAddingWaterId,
);

