import CustomButton from 'app/components/Button';
import Timer from 'app/components/Timer';
import errorIcon from 'assets/ic_error.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OtpInput } from 'reactjs-otp-input';
import { LOGIN_FAILED_BLOCKED } from 'utils/constants';

import { Box, Typography } from '@mui/material';

import { useLgStyles } from '../LoginPage/styles';
import { selectErrorMessage, selectLoading, selectLoginForm, selectSignupForm } from '../selectors';
import { actions } from '../slice';

function OTP({
  isRegister=false,
  formType
}:{
  isRegister?:boolean
  formType?: "PHONE" | "EMAIL"
}) {
  const classes = useLgStyles();
  const [otp, setOtp] = useState("")
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectLoading)
  const registerForm = useSelector(selectSignupForm)
  const loginForm = useSelector(selectLoginForm)
  const errorMessage = useSelector(selectErrorMessage)
  const [hasErrored, setHasErrored] = useState(false)

  useEffect(() => {
    if(errorMessage && errorMessage !== "LOGIN_FAILED_INACTIVE"){
      setHasErrored(true)
    }
  }, [errorMessage])

  const onSubmit = ()=> {
    if(isRegister){
      dispatch(actions.setRegisterOTP({
        code: otp,
        payerId: registerForm?.payerId,
        waterId: registerForm?.waterId,
        phoneNumber: registerForm?.phoneNumber
      }))
      return
    }
    dispatch(actions.setLoginOTP({
      code: otp,
      email: formType === "EMAIL" ? loginForm.email : "",
      phoneNumber: formType === "PHONE" ? loginForm.phoneNumber : ""
    }))
  }

  return (
    <Box
      style={{
        minWidth: "454px"
      }}
    >
      <Typography
        className={classes.title}
      >
        {`${t("AUTH.VERIFY_PHONE_PART_1")}`} <span style={{color:"#4596FF"}}>{`${t("AUTH.VERIFY_PHONE_PART_2")}`}</span> {`${t("AUTH.VERIFY_PHONE_PART_3")}`}
      </Typography>

      <OtpInput
        value={otp}
        onChange={(otp)=>{
          setHasErrored(false)
          setOtp(otp)
        }}
        numInputs={6}
        shouldAutoFocus
        containerStyle={{
          gap: 20,
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
          direction: "ltr"
        }}
        inputStyle={{
          borderRadius: "10px",
          border: `0.5px solid #E0E0E0`,
          fontFamily: "Roboto",
          fontSize: "20px",
          padding: "0 18px",
          fontWeight: 400,
          height: "56px",
          width: "56px",
        }}
        focusStyle={{
          outline: "none",
          border: `2px solid #4596FF`,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
        errorStyle={{
          outline: "none",
          border: `1px solid #BA0404`,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
        isInputNum
        hasErrored={hasErrored}
        isDisabled={isLoading}
      />
      {
        errorMessage === LOGIN_FAILED_BLOCKED ?
        <Typography
          sx={{
            textCenter: "center",
            color: "#BA0404",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontSize: "14px",
            marginTop: "16px",
          }}
        >
          {t("COMMON.LOGIN_FAILED_BLOCKED")}
        </Typography> :
        <Box
          marginTop="24px"
          marginBottom="20px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap="8px"
        >
          {
            errorMessage && hasErrored &&
            <>
              <img src={errorIcon} alt="error_icon" width="16px" height="16px" />
              <Typography className={classes.error}>
                {t("AUTH.OTP_ERROR_MESSAGE")}
              </Typography>
            </>
          }
          <Timer formType={formType} isRegister={isRegister}/>
        </Box>
      }
      <CustomButton
        onClick={onSubmit}
        btnStyle={{
          marginTop: "32px"
        }}
        disabled={otp?.length < 6 || isLoading}
        isLoading={isLoading}
      >
        <Typography className={classes.btnText}>{t("BUTTONS.NEXT")}</Typography>
      </CustomButton>
    </Box>
  );
}

export default OTP;
