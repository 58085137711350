import { Box, Typography, useTheme } from '@mui/material'
import React, { CSSProperties } from 'react'
import { useStyles } from './styles';
import InfoCard from '../InfoCard';

interface TextInputProps {
    label?: string;
    placeholder?: string;
    containerStyle?: CSSProperties;
    fullWidth?: boolean;
    type?: "text" | "number" | "email";
    onChange?: (e)=>void;
    value?: string;
    error?: string;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    name?: string;
    disabled?: boolean;
    onBlur?: (e:React.FocusEvent<HTMLTextAreaElement | HTMLInputElement> )=>void;
    infoSrc?: string;
}

export default function TextInput({
    label,
    placeholder,
    containerStyle,
    fullWidth=true,
    type="text",
    onChange,
    value,
    error,
    labelStyle,
    inputStyle,
    name,
    disabled,
    onBlur,
    infoSrc
}:TextInputProps) {
    const classes = useStyles()
    const errorStyle = () => {
        if(!error) return {}
        return {
            borderColor: "#DD0000"
        }
    }
    const theme = useTheme()
    const disabledStyle = () => {
        if(!disabled) return {}
        return {
            backgroundColor: "#F2F2F2",
        }
    }

    return (
        <Box
            style={containerStyle}
            sx={{
                direction: theme.direction
            }}
        >
            <Typography style={labelStyle} className={classes.label}>{label}</Typography>
            <Box
                sx={{
                    position: "relative"
                }}
            >
                <input
                    onChange={onChange}
                    type={type}
                    style={{
                        textAlign: theme.direction === "ltr" ? "start" : "end",
                        ...inputStyle,
                        ...errorStyle?.(),
                        ...disabledStyle?.()
                    }}
                    className={classes.input}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    disabled={disabled}
                    onBlur={onBlur}
                />
                {
                    infoSrc &&
                    <InfoCard src={infoSrc} />
                }
            </Box>
            {
                error &&
                <Typography className={classes.error} component="small">{error}</Typography>
            }
        </Box>
    )
}
