import React from "react";

import LogoImage from "assets/afcon_logo.png";

export const Logo = ({
  width="100%",
  height="100%"
}:{ width?: string | number; height?: string | number}) => {
  return (
    <>
      <img width={width} height={height} src={LogoImage} alt="Afcon Logo" />
    </>
  );
};
