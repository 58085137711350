import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "40px",
    fontWeight: 600,
    marginBottom: "41px"
  },
  btnText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 400,
  },
  supportText: {
    color: "#161616",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center"
  },
  contentWrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }
}));
