import {createSlice} from 'utils//@reduxjs/toolkit';

import {PayloadAction} from '@reduxjs/toolkit';

import {ErrorType} from 'types/ErrorResponse';
import {
  BarGraphDataResponse,
  ContainerState,
  DashboardData,
  WaterIdRequest,
  WaterIdResponse,
} from './types';
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
    loading: false,
    isAddingWaterId: false,
    error: null,
    dashboardData: {} as DashboardData,
    monthlyBarChartData: [],
    yearlyBarChartData: [],
    monthlyData: [],
    currentMonthForMonthly: '',
    currentMonthForYearly: '',
    waterIdResponse: null,
    isMonthSelected: false,
    addWaterIdError: null,
    meanForMonthly: 0,
    meanForYearly: 0,
    message: '',
    AvgMonthlyConsumptionLast12Months: 0,
};

const dashboardSlice = createSlice({
  name: 'dashboardState',
  initialState,
  reducers: {
    getResources(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
    },
    getResourcesSuccess(state, action: PayloadAction<{
      dashboardData:DashboardData,
      monthlyBarChartData:BarGraphDataResponse,
      yearlyBarChartData:BarGraphDataResponse
    }>) {
      state.dashboardData = action.payload?.dashboardData;
      state.monthlyBarChartData = action.payload?.monthlyBarChartData?.data;
      state.yearlyBarChartData = action.payload?.yearlyBarChartData?.data;
      state.currentMonthForMonthly = action.payload?.monthlyBarChartData?.data[0].layerDate;
      state.currentMonthForYearly = action.payload?.yearlyBarChartData?.data[0].layerDate;
      state.meanForMonthly = action.payload?.monthlyBarChartData?.mean;
      state.meanForYearly = action.payload?.yearlyBarChartData?.mean;
      state.loading = false;
      state.error = null;
    },
    getResourcesFailed(state, action: PayloadAction<ErrorType>) {
        state.loading = false;
        state.error = action.payload;
    },
    getDashBoardBarGraphDataFailed(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.error = action.payload;
    },
    addWaterId(state, action: PayloadAction<WaterIdRequest>) {
      state.isAddingWaterId = true;
      state.addWaterIdError = null;
      state.waterIdResponse = null;
    },
    addWaterIdSuccess(state, action: PayloadAction<WaterIdResponse>) {
      state.isAddingWaterId = false;
      state.addWaterIdError = null;
      state.waterIdResponse = action.payload;
    },

    addWaterIdFailed(state, action: PayloadAction<ErrorType>) {
      state.isAddingWaterId = false;
      state.waterIdResponse = null;
      state.addWaterIdError = action.payload;
    },
    resetAddWaterErrorMessage(state) {
      state.waterIdResponse = null;
      state.addWaterIdError = null;
    },

    setIsMonthly(state, action) {
      state.isMonthSelected = action.payload;
    },
  },
});

export const {actions, reducer, name: dashboardSliceKey} = dashboardSlice;
