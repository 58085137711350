import { Box, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React, { useState } from 'react'
import CustomButton from '../Button'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from '../ConfirmDialog'
import { useNavigate } from 'react-router-dom'
import { FormikProps } from 'formik'
import { ProfileData } from 'app/containers/Settings/types'
import { useSelector } from 'react-redux'
import { selectIsSaving } from 'app/containers/Settings/selector'
import storage from 'utils/storage'
import { useDispatch } from 'react-redux'
import { actions } from 'app/containers/Auth/slice'

export default function ActionButtons(Props:FormikProps<ProfileData>) {
    const { t } = useTranslation()
    const [showDialog, setShowDialog] = useState(false)
    const navigate = useNavigate()
    const isLoading = useSelector(selectIsSaving)
    const dispatch = useDispatch()

    const { handleSubmit, isSubmitting, resetForm } = Props


    const onLogout = async () => {
        if(isLoading||isSubmitting) return
        await Promise.all([storage.clear("sessionToken"), storage.clear("currentProfileId")])
        dispatch(actions.resetUser())
        navigate("/auth/login")
    }
    return (
        <Grid2 spacing={"24px"} container>
            <Grid2 xsOffset={6} xs={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "24px"
                    }}
                >
                    <CustomButton
                        fullWidth={true}
                        btnStyle={{
                            height: "42px",
                            borderRadius: "7px",
                            background: "#FFF",
                            boxShadow: "0px 4px 4px 0px rgba(227, 227, 227, 0.25)",
                        }}
                        onClick={()=>setShowDialog(true)}
                        disabled={isSubmitting||isLoading}
                    >
                        <Typography
                            sx={{
                                color: "#4596FF",
                                fontFamily: "Roboto",
                                fontSize: "18px",
                                fontWeight: 600,
                            }}
                        >
                            {t("BUTTONS.CANCEL")}
                        </Typography>
                    </CustomButton>
                    <CustomButton
                        fullWidth
                        btnStyle={{
                            height: "42px"
                        }}
                        onClick={()=>handleSubmit()}
                        isLoading={isSubmitting||isLoading}
                        disabled={isSubmitting||isLoading}
                    >
                        <Typography
                            sx={{
                                color: "#FEFEFE",
                                fontFamily: "Roboto",
                                fontSize: "18px",
                                fontWeight: 600,
                            }}
                        >
                            {t("BUTTONS.SAVE")}
                        </Typography>
                    </CustomButton>
                </Box>
            </Grid2>
            <Grid2 xs={12}>
                <Box
                    sx={{
                        margin: "40px 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Typography
                        onClick={onLogout}
                        sx={{
                            color: "#FF0202",
                            fontFamily: "Roboto",
                            fontSize: "24px",
                            fontWeight: 600,
                            letterSpacing: "0.96px",
                            cursor: "pointer"
                        }}
                    >
                        {t("DASHBOARD.LOG_OUT")}
                    </Typography>
                </Box>
            </Grid2>
            <ConfirmDialog
                open={showDialog}
                handleClose={()=>setShowDialog(false)}
                onSubmit={()=>{
                    resetForm()
                    setShowDialog(false)
                }}
            />
        </Grid2>
    )
}
