import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    input: {
        borderRadius: "8px",
        background: "#FFF",
        height: "46px",
        padding: "13px 4px",
        color: "#161616",
        fontFamily: "Roboto",
        fontSize: "20px",
    }
}));
