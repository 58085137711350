/**
 * Gets the repositories of the user from Player
 */

import {call, put, takeLatest} from 'redux-saga/effects';
import {API_URL} from 'utils/constants';
import {request} from 'utils/request';

import {LoggerClass} from 'utils/logger';
import {NotificationData, NotificationResponse} from './types';
import {actions} from './slice';
/**
 * Player repos request/response handler
 */

export function* getNotificationDataRequest({payload}: any) {
  try {
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}notifications?limit=${payload.limit}&page=${payload.page}`;
    const response: NotificationResponse = yield call(request, url, options);
    console.log("response=====>", response)
    yield put(actions.getNotificationDataSuccess(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.getNotificationDataFailed(errorData.message));
    }
  }
}

export function* requestUpdateToken({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}users/register-fcm`;
    const response: boolean = yield call(request, url, options);
    LoggerClass.log('response token-------', response);
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* notificationSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(
    actions.getNotificationData.type,
    getNotificationDataRequest,
  );
  yield takeLatest(actions.sendFcmToken.type, requestUpdateToken);
}
