/**
 * Homepage selectors
 */

import {RootState} from '../../../types';

import {createSelector} from '@reduxjs/toolkit';
import {initialState} from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) =>
  state?.notificationState || initialState;

export const selectLoading = createSelector([selectDomain], state => state.loading);

export const selectNotificationList = createSelector(
  [selectDomain],
  state => state.notificationList,
);
export const error = createSelector([selectDomain], state => state.error);

export const myMetaInfo = createSelector([selectDomain], state => state.meta);

export const isMoreLoading = createSelector(
  [selectDomain],
  state => state.isMore,
);
