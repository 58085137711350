import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    title: {
        fontFamily: "Cairo",
        fontSize: "20px",
        fontWeight: 500,
        textTransform: "capitalize"
    }
}));
