/**
 * Gets the repositories of the user from Player
 */

import {all, call, put, takeLatest} from 'redux-saga/effects';
import {API_URL} from 'utils/constants';
import {request} from 'utils/request';

import {LoggerClass} from 'utils/logger';
import {GraphTypeEnum, WaterIdResponse} from './types';
import {actions} from './slice';
import {ENG_TO_HE} from 'utils/helpers';
import { toast } from 'react-toastify';

export function* getDashBoardBarGraphDataRequest({payload}: any) {
  try {
    const options = {
      method: 'GET',
    };
    console.log('payload=====>', payload);
    const url = `${API_URL}dashboard/graph/${payload.id}?type=${payload.type}`;
    // @ts-ignore
    const response: any = yield call(request, url, options);
    // if (payload.type === GraphTypeEnum.THIRTY_DAYS)
    //   yield put(actions.getDashBoardBarGraphDataSuccess(response));
    // else yield put(actions.getGraphData(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.getDashBoardBarGraphDataFailed(errorData.message));
      toast.error(ENG_TO_HE(`COMMON.${errorData.message}`));
    }
  }
}

export function* getResources({payload}: any) {
  try {
    const options = {
      method: 'GET',
    };
    const monthlyBarGraphDataUrl = `${API_URL}dashboard/graph/${payload}?type=${GraphTypeEnum.THIRTY_DAYS}`;
    const yearlyBarGraphDataUrl = `${API_URL}dashboard/graph/${payload}?type=${GraphTypeEnum.TWELVE_MONTHS}`;
    const dashboardInfoUrl = `${API_URL}dashboard/info/${payload}`;
    const response: any = yield all([
      call(request, monthlyBarGraphDataUrl, options),
      call(request, yearlyBarGraphDataUrl, options),
      call(request, dashboardInfoUrl, options),
    ]);
    yield put(actions.getResourcesSuccess({
      monthlyBarChartData: response[0],
      yearlyBarChartData: response[1],
      dashboardData: response[2],
    }));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      toast(ENG_TO_HE(`COMMON.${errorData.message}`))
      yield put(actions.getResourcesFailed(errorData.message));
    }
  }
}

export function* addWaterIdDataRequest({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}users/profiles/add-water-id`;

    const response: WaterIdResponse = yield call(request, url, options);
    yield put(actions.addWaterIdSuccess(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.addWaterIdFailed(errorData.message));
    }
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* dashboardSaga() {
  yield takeLatest(actions.getResources.type, getResources);
  yield takeLatest(actions.addWaterId.type, addWaterIdDataRequest);
}
