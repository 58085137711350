import caution from 'assets/caution.svg';
import warning from 'assets/warning.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/constants';

import { Box, Typography } from '@mui/material';

import Card from '../Card';

export default function Notification({
    item
}) {
    const {t} = useTranslation();
    const createdAt = item.createdAt
    ? dateFormat(item.createdAt, 'DD/MM/YYYY hh:mm:ss')
    : '';
    return (
        <Card
            style={{
                padding: "8px 26px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
                marginBottom: "24px"
            }}
        >
            <Box
                sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "10px",
                    background: item?.severity === "WARNING" ? "#FC403C" : "#557AC2",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}
            >
                <img alt="caution" width="32px" height="32px" src={item?.severity === "WARNING" ? warning : caution} />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                gap="6px"
            >
                <Typography
                    sx={{
                        color: "#161616",
                        fontFamily: "Open Sans",
                        fontSize: "18px",
                        fontWeight: 400,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >
                    {
                        item.title.includes("NOTIFICATIONS.ALARMS.") ?
                        t(`${item.title}.TITLE`) :
                        item.title
                    }
                </Typography>
                <Typography
                    sx={{
                        color: "#1D1F20",
                        fontFamily: "Open Sans",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontWeight: 600,
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        minHeight: "45px"
                    }}
                >
                    {item.message || t(`${item.title}.DESCRIPTION`, item.params)}
                </Typography>
                <Typography
                    sx={{
                        color: "#87959F",
                        fontFamily: "Open Sans",
                        fontSize: "10px",
                        fontWeight: 400
                    }}
                >
                    {createdAt}
                </Typography>
            </Box>
        </Card>
    )
}
