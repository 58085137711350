import {createSlice} from 'utils//@reduxjs/toolkit';

import {PayloadAction} from '@reduxjs/toolkit';

import {
  ContainerState,
  ProfileData,
  ProfileRequest,
  UploadImageResponse,
} from './types';
import { ErrorType } from 'types/ErrorResponse';

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  loading: false,
  isUploading: false,
  isSaving: false,
  error: null,
  profileData: {
    language: 'en',
    notificationHiddenLeakageSettings: {
      isEnabled: true,
    },
    notificationRapidLeakageSettings: {
      isEnabled: true,
    },
  } as ProfileData,
  uploadedImage: null,
  updated: false
};

const settingsSlice = createSlice({
  name: 'settingsState',
  initialState,
  reducers: {

    getProfileData(state) {
      state.loading = true;
      state.error = null;
      state.profileData = {} as ProfileData;
    },
    onSuccess(state, action: PayloadAction<ProfileData>) {
      state.loading = false;
      state.isSaving = false;
      state.error = null;
      state.updated = true;
      state.profileData = action.payload;
    },
    onFailure(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.isUploading = false;
      state.isSaving = false;
      state.error = action.payload;
      state.updated = false;
    },
    updateProfileData(state, action: PayloadAction<ProfileRequest>) {
      state.isSaving = true;
      state.error = null;
      state.updated = false;
    },
    uploadImage(state, action: PayloadAction<any>) {
      state.isUploading = true;
      state.error = null;
    },
    onSuccessUploadImage(state, action: PayloadAction<UploadImageResponse>) {
      state.isUploading = false;
      state.error = null;
      state.uploadedImage = action.payload;
    },
    removeImage(state) {
      state.profileData.image = null;
    },
    resetSettingsUserData(state) {
      state.profileData = initialState.profileData;
      state.error = null;
      state.loading = false;
    },
    setProfileData(state, action: PayloadAction<ProfileData>) {
      state.profileData = action.payload;
    }
  },
});

export const {actions, reducer, name: settingsSliceKey} = settingsSlice;
