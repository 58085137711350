import { Box } from '@mui/material'
import React, { CSSProperties } from 'react'
import { useStyles } from './styles'

export default function Card({
    children,
    style
}:{
    children: React.ReactNode;
    style?: CSSProperties;
}) {
    const classes = useStyles()
    return (
        <Box
            style={style}
            className={classes.container}
        >
            {children}
        </Box>
    )
}
