import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Card from 'app/components/Card'
import Layout from 'app/components/Layout'
import RingChart from 'app/components/RingChart'
import React, { useEffect } from 'react'
import { useStyles } from './styles'
import Consumption from 'app/components/Consumption'
import UserCard from 'app/components/UserCard'
import { useSelector, useDispatch } from 'react-redux'
import { selectLoading, selectDashboardData, selectMonthlyMean, selectYearlyMean, selectYearlyData, selectMonthlyData } from './selector'
import { actions } from './slice'
import DayBarChart from 'app/components/DayBarChart'
import MonthBarChart from 'app/components/MonthBarChart'
import { useTranslation } from 'react-i18next'
import { selectCurrentProfileId, selectIsDailyConsumptionActive, selectIsMonthlyConsumptionActive } from '../Auth/selectors'

export default function Dashboard() {
    const classes = useStyles()
    const isLoading = useSelector(selectLoading)
    const dashboardInfo = useSelector(selectDashboardData)
    const monthlyData = useSelector(selectMonthlyData)
    const yearlyData = useSelector(selectYearlyData)
    const monthlyMean = useSelector(selectMonthlyMean)
    const yearlyMean = useSelector(selectYearlyMean)
    const currentProfileId = useSelector(selectCurrentProfileId)
    const isDailyConsumptionActive = useSelector(selectIsDailyConsumptionActive)
    const isMonthlyConsumptionActive = useSelector(selectIsMonthlyConsumptionActive)
    const { t } = useTranslation()
    const theme = useTheme()

    const dispatch = useDispatch()

    useEffect(() => {
        if(currentProfileId) {
            dispatch(actions.getResources(currentProfileId))
        }
    }, [currentProfileId, dispatch])


    const monthlyReadingPercent = dashboardInfo.monthlyAverage > 0 ? (
            (dashboardInfo.lastMonth / dashboardInfo.monthlyAverage) * 100
          ).toFixed(2) : 0;
    const dailyReadingPercent = dashboardInfo.dailyAverage > 0 ? (
            (dashboardInfo.lastDay / dashboardInfo.dailyAverage) * 100
          ).toFixed(2) : 0;

    return (
        <Layout>
            {
                isLoading &&
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <CircularProgress
                        size={40}
                        color="primary"
                    />
                </Box>

            }
            <Grid2 spacing="50px" container>
                <Grid2 xs={7}>
                    <Box>
                        {/* <Box marginBottom="70px">
                            <UserCard />
                        </Box> */}
                        <Grid2 spacing={"28px"} container>
                            {
                                isDailyConsumptionActive &&
                                <Grid2 xs={6}>
                                    <RingChart
                                        percentage={Number(dailyReadingPercent)}
                                    />
                                </Grid2>
                            }
                            {
                                isMonthlyConsumptionActive &&
                                <Grid2 xs={6}>
                                    <RingChart
                                        type='MONTHLY'
                                        percentage={Number(monthlyReadingPercent)}
                                    />
                                </Grid2>
                            }
                        </Grid2>
                        <Box marginTop="70px">
                            <Consumption
                                dashboardInfo={dashboardInfo}
                            />
                        </Box>
                    </Box>
                </Grid2>
                <Grid2 xs={5}>
                    <Box
                        sx={{
                            direction: theme.direction
                        }}
                    >
                        <Typography className={classes.title}>{t(`DASHBOARD.DAILY_GRAPHS`)}</Typography>
                        <Card>
                            <DayBarChart
                                barGraphInfo={monthlyData}
                                yesterdayData={monthlyData[monthlyData.length - 1]}
                                isDays={true}
                                averageConsumption={monthlyMean}
                                isLoading={isLoading}
                            />
                        </Card>
                    </Box>
                    <Box
                        marginTop="70px"
                        sx={{
                            direction: theme.direction
                        }}
                    >
                        <Typography className={classes.title}>{t(`DASHBOARD.MONTHLY_GRAPHS`)}</Typography>
                        <Card>
                            <MonthBarChart
                                monthlyBarInfo={yearlyData}
                                yesterdayMonthData={yearlyData[yearlyData.length - 1]}
                                isDays={false}
                                averageMonthConsumption={yearlyMean}
                                isLoading={isLoading}
                            />
                        </Card>
                    </Box>
                </Grid2>
            </Grid2>
        </Layout>
    )
}
