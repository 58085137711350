import { selectUser } from 'app/containers/Auth/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import SelectInput from '../SelectInput';
import TextInput from '../TextInput';
import { FormikProps } from 'formik';
import { ProfileData } from 'app/containers/Settings/types';
import { APP_LANGUAGE } from 'utils/constants';
import { selectIsSaving } from 'app/containers/Settings/selector';

const LANGUAGES = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'עברית',
    value: 'he',
  },
  {
    label: 'اللغة العربية',
    value: 'ar',
  },
];

export default function SettingsForm(Props:FormikProps<ProfileData>) {
    const { t } = useTranslation()
    const user = useSelector(selectUser)
    const formDetails = Props.values
    const { setFieldValue } = Props
    const isLoading = useSelector(selectIsSaving)
    return (
        <Grid2 spacing={"30px"} container>
            <Grid2 xs={6}>
                <TextInput
                    disabled
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415"
                    }}
                    inputStyle={{
                        height: "42px",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}
                    value={formDetails?.appMeasureEntity?.displayConsumerName?.split(' ')?.[0]}
                    label={t("SETTINGS.NAME")}
                    placeholder={t("SETTINGS.NAME")}
                />
            </Grid2>
            <Grid2 xs={6}>
                <TextInput
                    disabled
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415",
                        fontFamily: "Roboto",
                    }}
                    inputStyle={{
                        height: "42px",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}
                    value={formDetails?.appMeasureEntity?.displayConsumerName?.split(' ')?.[1]}
                    label={t("SETTINGS.SUR_NAME")}
                    placeholder={t("SETTINGS.SUR_NAME")}
                />
            </Grid2>
            <Grid2 xs={6}>
                <TextInput
                    disabled
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415"
                    }}
                    inputStyle={{
                        height: "42px",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}
                    value={user?.phoneNumber}
                    label={t("SETTINGS.PHONE_NUMBER")}
                    placeholder={t("SETTINGS.PHONE_NUMBER")}
                />
            </Grid2>
            <Grid2 xs={6}>
                <TextInput
                    disabled
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415"
                    }}
                    inputStyle={{
                        height: "42px",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}
                    value={formDetails?.accountSecondaryId}
                    label={t("AUTH.WATER_ID")}
                    placeholder={t("AUTH.WATER_ID")}
                />
            </Grid2>
            <Grid2 xs={6}>
                <TextInput
                    disabled
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415"
                    }}
                    inputStyle={{
                        height: "42px",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}
                    value={formDetails?.appMeasureEntity?.address}
                    label={t("SETTINGS.ADDRESS")}
                    placeholder={t("SETTINGS.ADDRESS")}
                />
            </Grid2>
            <Grid2 xs={6}>
                <TextInput
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415"
                    }}
                    inputStyle={{
                        height: "42px",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}
                    disabled={user?.loginSource === "EMAIL" || isLoading}
                    value={formDetails?.email??""}
                    onChange={(e)=>setFieldValue("email", e.target.value)}
                    label={t("AUTH.EMAIL")}
                    placeholder={t("AUTH.EMAIL")}
                    error={Props.touched.email && !!Props.errors.email ? t(Props.errors.email) : ""}
                />
            </Grid2>
            <Grid2 marginTop={"20px"} marginBottom={"51px"} xs={12}>
                <SelectInput
                    labelStyle={{
                        marginLeft: "0px",
                        color: "#050415"
                    }}
                    disabled={isLoading}
                    label={t("SETTINGS.LANGUAGE")}
                    inputStyle={{
                        padding: "10px 16px",
                        borderRadius: "16px !important",
                        height: "42px !important",
                        border: "0.5px solid #E0E0E0",
                        background: "#FFF",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "-0.408px"
                    }}

                    onChange={(e)=>setFieldValue("language", e.target.value?.toUpperCase())}
                    value={formDetails?.language?.toLowerCase()||user?.language?.toLowerCase()||APP_LANGUAGE}
                    options={LANGUAGES}
                />
            </Grid2>
        </Grid2>
    )
}
