import { UserResponse } from "../types";

export type LoginForm = {
  email?: string;
  phoneNumber?: string;
}
export type SignupForm = {
  phoneNumber: string;
  payerId: string;
  waterId: string;
}
/* --- STATE --- */
export interface AuthState {
  loading: boolean;
  error?: LoginErrorType | any;
  loginForm: LoginForm,
  signupForm: SignupForm
  token: string;
  authRequestSuccess: boolean;
  initialPage: "/" | null | "/auth/all-set";
  otp: string;
  agreedToTerms: boolean;
  user: UserResponse | null;
  settingConfig: SettingConfig;
  isDrawerOpen: boolean;
}

export interface SettingConfig {
  payerIdImage: string;
  waterIdImage: string;
}

export enum LoginErrorType {
  EMAIL_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
  INVALID_OTP = 400
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = AuthState;
