import { Box, Typography } from '@mui/material'
import React from 'react'
import droplet from 'assets/droplet.svg'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

export default function ConsumptionCard({
    type="DAILY",
    label="Last Day",
    opacity,
    value=0
}:{
    type?: "DAILY" | "MONTHLY"
    label?: string
    opacity?: any
    value?: number
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="12px"
            width="100%"
            justifyContent="center"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "170px",
                    justifyContent: "space-between"
                }}
            >
                <Box>
                    <Typography className={classes.label}>{label}</Typography>
                    <Typography sx={{color: type === "DAILY" ? "#4596FF" : "#58BC7C", opacity:opacity}} className={classes.value}>{t("COMMON.M3",{value: value?.toFixed(1)})}</Typography>
                </Box>
                <Box>
                    <img src={droplet} alt="dropket" />
                </Box>
            </Box>
        </Box>
    )
}
