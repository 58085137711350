import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLgStyles } from "./styles";
import { actions } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthRequestSuccess, selectInitialPage } from "../selectors";
import Login from "app/components/LoginForm";
import OTP from "../OtpPage/form";
import { useNavigate } from "react-router-dom";


function LoginForm({ className }) {
  const classes = useLgStyles();
  const dispatch = useDispatch();
  const isSuccessful = useSelector(selectAuthRequestSuccess)
  const [viewType, setViewType] = useState<"login" | "otp">("login")
  const [formType, setFormType] = useState<"PHONE" | "EMAIL">("PHONE")
  const initialPage = useSelector(selectInitialPage)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccessful) {
      dispatch(actions.setAuthRequestSuccess(false))
      setViewType("otp")
      if(initialPage && viewType === "otp"){
        navigate(`${initialPage}`)
      }
    }
  }, [isSuccessful, initialPage, viewType, dispatch])

  return (
    <Box className={classes.contentWrapper}>
      {
        viewType === "login" &&
        <Login
          loginForm={formType}
          setLoginForm={setFormType}
        />
      }
      {
        viewType === "otp" &&
        <OTP formType={formType} />
      }
    </Box>
  );
}

export default LoginForm;
