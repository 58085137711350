import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "40px",
    fontWeight: 600,
    marginBottom: "41px"
  },
  btnText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 400,
  },
  supportText: {
    color: "rgba(0, 0, 0, 0.60)",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "150%", /* 27px */
    letterSpacing: "-0.408px",
    maxWidth: "320px",
    marginBottom: "50px"
  },
  contentWrapper: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  linkText: {
    color: "#AFB2B5",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "400",
  }
}));
