import { Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import CustomButton from "app/components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Logo } from "app/components/Logo";
import { Link } from "react-router-dom";

const Welcome = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box
        className={classes.contentWrapper}
    >
        <Box
            sx={{
                marginBottom: "30px"
            }}
        >
            <Logo width="205px" height="auto" />
        </Box>

        <Typography
            className={classes.supportText}
        >
            {`${t("AUTH.WELCOME_TEXT_1")}`} <span style={{color:"rgba(0, 0, 0, 0.80)"}}>{`${t("AUTH.WELCOME_TEXT_2")}`}</span>
        </Typography>
        <CustomButton
            btnStyle={{
                maxWidth: "454px",
                marginBottom: "32px"
            }}
            onClick={()=>navigate("/auth/signup")}
        >
            <Typography className={classes.btnText}>{t("BUTTONS.GET_STARTED")}</Typography>
        </CustomButton>
        <Typography
            className={classes.linkText}
        >
            {`${t("AUTH.ALREADY_JOINED_US_?")}`} <Link style={{ textDecoration: "none"}} to="/auth/login"><span style={{color:"#82BCEE"}}>{`${t("AUTH.LOGIN_HERE")}`}</span></Link>
        </Typography>
    </Box>
  );
};

export default Welcome;
