import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        borderRadius: "20px",
        background: "#FEFEFE",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
        width: "100%"
    }
}));
