import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Typography, Divider } from '@mui/material';
import closeIcon from 'assets/ic_close.svg'
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface NotificationExceedingProps {
    open: boolean
    handleClose: () => void
    onUpload: () => void
    onRemove: () => void
}

export default function UploadImageDialog({
    open,
    handleClose,
    onUpload,
    onRemove
}:NotificationExceedingProps) {
    const { t } = useTranslation()
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "20px",
                    border: "0.5px solid #FFF",
                    background: "#FFF",
                    padding: "16px 37px",
                    minWidth: "350px",
                    position: "relative",
                    justifyContent: "center",
                    minHeight: "150px"
                }
            }}
        >
            <Box
                sx={{
                    marginBottom:"17px",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Button
                    sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent:"center",
                        alignItems: "center",
                        backgroundColor: "rgba(69, 150, 255, 0.40)",
                        minWidth: "0px",
                        position: "absolute",
                        top: "16px",
                        right: "16px"
                    }}
                    onClick={handleClose}
                >
                    <img alt="close" src={closeIcon} width="16px" height="16px" />
                </Button>
            </Box>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: '500',
                            color: "#4596FF",
                            textAlign: 'center',
                            cursor: "pointer"
                        }}
                        onClick={()=>{
                            onUpload()
                            handleClose()
                        }}
                    >
                        {t("SETTINGS.UPLOAD_PHOTO")}
                    </Typography>
                </Box>
                <Divider
                    sx={{
                        margin: "10px 0px"
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: '500',
                            color: "#4596FF",
                            textAlign: 'center',
                            cursor: "pointer"
                        }}
                        onClick={()=>{
                            onRemove()
                            handleClose()
                        }}
                    >
                        {t("SETTINGS.REMOVE_PHOTO")}
                    </Typography>
                </Box>
            </Box>
        </Dialog>
    );
}