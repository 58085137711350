/**
 * Gets the repositories of the user from Player
 */

import {call, delay, put, takeLatest} from 'redux-saga/effects';
import {API_URL} from 'utils/constants';
import {request} from 'utils/request';
import {LoggerClass} from 'utils/logger';
import storage from 'utils/storage';
import {BarGraphData, ListDataResponse, OptionsList} from './types';
import {actions} from './slice';
import { toast } from 'react-toastify';
import { ENG_TO_HE } from 'utils/helpers';
/**
 * Player repos request/response handler
 */

export function* getOptionListRequest() {
  try {
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}usage/graph-options`;

    const response: OptionsList[] = yield call(request, url, options);
    yield put(actions.getOptionListSuccess(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.getOptionListFiled(errorData.message));
    }
  }
}

export function* getListDataRequest({payload}: any) {
  try {
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}usage/list/${payload.id}?min=${payload.startDate}&max=${payload.endDate}&limit=${payload.limit}&page=${payload.page}`;
    const response: ListDataResponse = yield call(request, url, options);
    console.log("response========>", response)
    yield put(actions.getListDataSuccess(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.getListDataFailed(errorData.message));
      toast(errorData.message);
    }
  }
}

export function* getUsageBarGraphDataRequest({payload}: any) {
  try {
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}usage/graph/${payload.id}?type=${payload.type}&options=${payload.option}`;

    const response: BarGraphData = yield call(request, url, options);
    if(payload.type === 'DAILY') {
      yield put(actions.setDailyBarGraphData(response));
      return
    }
    if(payload.type === 'MONTHLY') {
      yield put(actions.setMonthlyBarGraphData(response));
      return
    }
    if(payload.type === 'WEEKLY') {
      yield put(actions.setWeeklyBarGraphData(response));
      return
    }
    throw new Error('No type found');
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    if(err.message){
      yield put(actions.getUsageBarGraphDataFailed(err.message));
      return
    }
    let errorData = JSON?.parse(err?.message);
    if (errorData.message) {
      yield put(actions.getUsageBarGraphDataFailed(errorData.message));
      return
    }
  }
}

export function* downloadFile({
  id,
  startDate,
  endDate
}: {
  id: number;
  startDate: string;
  endDate: string;
}) {
  try {
    const options = {
      method: 'GET',
      'Content-Type': 'application/json',
    };

    const url = `${API_URL}usage/list/${id}/download?min=${startDate}&max=${endDate}`
    const path: string = yield call(request, url, options);
    console.log('path', path);
    return path;
  } catch (error:any) {
    yield put(actions.getListDataDownloadFailed(error?.message));
  }
}

export function* getListDataDownloadRequest({payload}: any) {
  try {
    const path: string = yield downloadFile({
      id: payload.id,
      startDate: payload.startDate,
      endDate: payload.endDate,
    });
    if(!path) {
      throw new Error('FILE_DOWNLOADED_ERROR');
    }
    yield put(actions.getListDataDownloadSuccess(path));
  } catch (error:any) {
    yield put(actions.getListDataDownloadFailed(error?.message));
    toast(ENG_TO_HE(error?.message));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* usageSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getListData.type, getListDataRequest);
  yield takeLatest(
    actions.getDailyBarGraphData.type,
    getUsageBarGraphDataRequest,
  );
  yield takeLatest(
    actions.getWeeklyBarGraphData.type,
    getUsageBarGraphDataRequest,
  );
  yield takeLatest(
    actions.getMonthlyBarGraphData.type,
    getUsageBarGraphDataRequest,
  );
  yield takeLatest(
    actions.getListDataDownload.type,
    getListDataDownloadRequest,
  );
  yield takeLatest(actions.getOptionList.type, getOptionListRequest);
}
