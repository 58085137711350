/**
 * Gets the repositories of the user from Player
 */

import { th } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { ENG_TO_HE } from 'utils/helpers';
import { LoggerClass } from 'utils/logger';
import { request } from 'utils/request';
import storage from 'utils/storage';

import { actions } from './slice';
import { ProfileData, UploadImageResponse } from './types';

/**
 * Player repos request/response handler
 */

export function* getProfileDataRequest() {
  try {
    const currentProfileId: string = yield storage.get('currentProfileId');
    if(!currentProfileId) throw new Error('REFRESH_PAGE');
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}users/profiles/${currentProfileId}`;

    const response: ProfileData = yield call(request, url, options);
    yield put(actions.onSuccess(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      toast(ENG_TO_HE(`COMMON.${errorData.message}`))
      yield put(actions.onFailure(errorData.message));
      return
    }
    toast(ENG_TO_HE(`COMMON.${err.message}`))
  }
}

export function* updateProfileDataRequest({payload}: any) {
  LoggerClass.log('JSON.stringify(payload)---', JSON.stringify(payload));
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}users/profiles/${payload.id}`;

    const response: ProfileData = yield call(request, url, options);
    yield put(actions.onSuccess(response));
    toast(ENG_TO_HE('SETTINGS.SUCCESS_MSG'))
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.onFailure(errorData.message));
      let msg = 'SETTINGS.ERROR_MSG'
      if(errorData.message === "USER.ERRORS.EMAIL_ALREADY_EXISTS")
        msg = 'SETTINGS.EMAIL_ALREADY_EXISTS';
      toast(ENG_TO_HE(msg))
    }
  }
}

export function* uploadImage({payload}: any) {
  try {
    const form = new FormData();
    form.append('file', payload)
    const sessionToken: string = yield storage.get('sessionToken');
    const options = {
      method: 'POST',
      body: form,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    };
    const url = `${API_URL}uploads`;
    const response: UploadImageResponse = yield call(request, url, options);
    yield put(actions.onSuccessUploadImage(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.onFailure(errorData.message));
      toast(ENG_TO_HE('SETTINGS.ERROR_MSG'))
    }
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* settingsSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getProfileData.type, getProfileDataRequest);
  yield takeLatest(actions.updateProfileData.type, updateProfileDataRequest);
  yield takeLatest(actions.uploadImage.type, uploadImage);
}
