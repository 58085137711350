
import * as React from 'react';
import { LoginPage } from './LoginPage/Loadable';
import { Navigate } from 'react-router-dom';
import LanguageDetector from 'app/components/LanguageDetector';

export function AuthPage() {
  const auth = localStorage.getItem('sessionToken');
  const pathname = window.location.pathname;
  if(auth && pathname !== '/auth/all-set'){
    return <Navigate to="/" replace />
  }
  return (
    <LanguageDetector>
      <LoginPage />
    </LanguageDetector>
  );
}
