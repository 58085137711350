import { createSlice } from "utils/@reduxjs/toolkit";

import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, LoginErrorType, SignupForm, SettingConfig } from "./types";
import { UserResponse } from "../types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  loginForm: {
    email: "",
    phoneNumber: ""
  },
  signupForm: {
    payerId: "",
    waterId: "",
    phoneNumber: ""
  },
  otp: "",
  loading: false,
  error: null,
  token: localStorage.getItem("sessionToken") || "",
  authRequestSuccess: false,
  initialPage: null,
  agreedToTerms: false,
  settingConfig: {
    payerIdImage: "",
    waterIdImage: ""
  },
  user: null,
  isDrawerOpen: false
};

const formSlice = createSlice({
  name: "authState",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, (state) => {
      state.token = "";
    });
  },
  reducers: {
    emailLogin: (state, action: PayloadAction<{email:string}>) => {
      state.loginForm.email = action.payload?.email;
      state.error = null;
      state.loading = true;
      state.initialPage = null;
    },
    phoneNumberLogin: (state, action: PayloadAction<{phoneNumber:string}>) => {
      state.loginForm.phoneNumber = action.payload?.phoneNumber;
      state.error = null;
      state.loading = true;
      state.initialPage = null;
    },
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
    setRegisterForm: (state, action: PayloadAction<SignupForm>) => {
      state.signupForm = action.payload;
      state.error = null;
      state.loading = true;
      state.initialPage = null;
    },
    setAuthError(state, action: PayloadAction<LoginErrorType>) {
      state.error = action.payload;
      state.authRequestSuccess = false;
      state.loading = false;
    },
    setAuthLoader(state, action: PayloadAction<boolean>) {
      state.error = null;
      state.loading = action.payload;
    },
    setAuthRequestSuccess(state, action: PayloadAction<boolean>) {
      state.authRequestSuccess = action.payload;
      state.loading = false
    },
    setInitialPage(state, action: PayloadAction<"/auth/all-set" | "/">) {
      state.loading  = false;
      state.error = null;
      state.initialPage = action.payload;
      state.authRequestSuccess = true;
    },
    setLoginOTP(state, action: PayloadAction<{code:string, phoneNumber?: string, email?: string}>) {
      state.loading = true;
      state.error = null;
      state.otp = action.payload?.code;
    },
    setRegisterOTP(state, action: PayloadAction<{code:string, waterId:string, payerId:string, phoneNumber: string}>) {
      state.loading = true;
      state.error = null;
      state.otp = action.payload?.code
    },
    checkAgreedToTerms(state, action: PayloadAction<boolean>) {
      state.loading = true;
      state.error = null;
      state.agreedToTerms = action.payload
    },
    setUser(state, action: PayloadAction<UserResponse>) {
      state.loading = false;
      state.error = null;
      state.user = action.payload
    },
    getUser(state, action: PayloadAction<{callback?:()=>void;}>) {
      state.loading = true;
      state.error = null;
    },
    setSettingConfig(state, action: PayloadAction<SettingConfig>) {
      state.settingConfig = action.payload;
      state.error = null;
    },
    getSettingConfig(state) {
      state.error = null;
    },
    resetUser(state) {
      state.user = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetState(state) {
      state = {
        ...initialState,
        loading: false
      };
    }
  },
});

export const { actions, reducer, name: sliceKey } = formSlice;
