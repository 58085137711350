/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.authState || initialState;

export const selectToken = createSelector(
  [selectDomain],
  authState => authState.token,
);

export const selectLoading = createSelector(
  [selectDomain],
  authState => authState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  authState => authState.error,
);

export const selectAuthRequestSuccess = createSelector(
  [selectDomain],
  authState => authState.authRequestSuccess
)

export const selectSignupForm = createSelector(
  [selectDomain],
  authState => authState.signupForm
)

export const selectLoginForm = createSelector(
  [selectDomain],
  authState => authState.loginForm
)

export const selectInitialPage = createSelector(
  [selectDomain],
  authState => authState.initialPage
)

export const selectUser = createSelector(
  [selectDomain],
  authState => authState.user
)

export const selectUserProfiles = createSelector(
  [selectDomain],
  authState => authState.user?.userProfiles
)

export const selectCurrentProfile = createSelector(
  [selectDomain],
  authState => authState.user?.userProfiles?.find(profile => profile.id === localStorage.getItem('currentProfileId'))
)

export const selectSettingConfig = createSelector(
  [selectDomain],
  authState => authState.settingConfig
)

export const selectIsDrawerOpen = createSelector(
  [selectDomain],
  authState => authState.isDrawerOpen
)

export const selectErrorMessage = createSelector([selectDomain], state => state.error)

export const selectCurrentProfileId = createSelector(
  [selectCurrentProfile],
  profile => profile?.id
);

export const selectIsDailyConsumptionActive = createSelector(
  [selectCurrentProfile],
  state =>
    state?.appMeasureEntity?.waterCompany?.waterCompaniesParameters?.isDailyConsumptionActive,
);

export const selectIsMonthlyConsumptionActive = createSelector(
  [selectCurrentProfile],
  state =>
    state?.appMeasureEntity?.waterCompany?.waterCompaniesParameters?.isMonthlyConsumptionActive,
);

export const selectIsHiddenLeakageActive = createSelector(
  [selectCurrentProfile],
  state =>
    state?.appMeasureEntity?.waterCompany?.waterCompaniesParameters?.isHiddenLeakageActive,
);

export const selectIsRapidLeakageActive = createSelector(
  [selectCurrentProfile],
  state =>
    state?.appMeasureEntity?.waterCompany?.waterCompaniesParameters?.isRapidLeakageActive,
);