import moment from 'moment-timezone';

export const API_URL = process.env.REACT_APP_API_URL;
export const APP_LANGUAGE = 'en';
export const __DEV_ANALYTICS_LOG__ = true;
export const ANALYTICS_EVENTS_ENABLED = true;
export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const TERMS_OF_USE = process.env.REACT_APP_TERMS_OF_USE;

export const INVALID_OTP = 'MESSAGES.AUTH.INVALID_OTP';
export const MESSAGE_USER_ALREADY_EXISTS = 'USER.ERRORS.EMAIL_ALREADY_EXISTS';
export const OTP_SEND_SUCCESSFULLY = 'OTP Send Successfully';
export const LOGIN_ERROR = 'USERS.ERRORS.NOT_FOUND';
export const SIGNUP_SUCCESSFULLY = 'MESSAGES.SIGN_UP.SUCCESS';
export const LOGIN_SUCCESSFULLY = 'MESSAGES.USER.LOGIN_SUCCESS';
export const SELECT_PLAN_SUCCESSFULLY = 'MESSAGES.CHANGE_PLAN_SUCCESS';
export const SELECT_PAYMENT_SUCCESSFULLY =
  'MESSAGES.CHANGE_PAYMENT_METHOD_SUCCESS';
export const INVALID_MOBILE_NUMBER = 'PHONE_NO_DO_NOT_MATCH_WATERID_AND_PAYER';
export const INVALID_PAYER_ID = 'WATERID_AND_PAYER_DO_NOT_MATCH_A_CONSUMER';

export const MESSAGES_SUCCESS = 'MESSAGES.SUCCESS';
export const USERS_ERRORS_USER_NOT_FOUND = 'USERS.ERRORS.USER_NOT_FOUND';
export const DRIVING_LICENSE = 'DRIVING_LICENSE';
export const VEHICLE_LICENSE = 'VEHICLE_LICENSE';
export const INSURANCE = 'INSURANCE';
export const LOGIN_FAILED_BLOCKED = 'LOGIN_FAILED_BLOCKED';
export const OTP_SENT = 'MESSAGES.AUTH.OTP_SENT';
export const USERS_ERRORS_USER_NOT_FOUND_WITH_EMAIL_PLEASE_REGISTER =
  'USERS.ERRORS.USER_NOT_FOUND_WITH_EMAIL_PLEASE_REGISTER';

export const USERS_ERRORS_USER_NOT_FOUND_WITH_PHONE_PLEASE_REGISTER =
  'USERS.ERRORS.USER_NOT_FOUND_WITH_PHONE_PLEASE_REGISTER';

export const MONTHS_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const dateFormat = (value: string, format: string = 'YYYY-MM-DD') => {
  return moment(value).format(format);
};

export function getDate30DaysAgo(): Date {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 30);
  return currentDate;
}

export function getDateDayAgo(): Date {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return currentDate;
}

export function numberWithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export const FORGOT_TIMER = 60 * 5