import { selectLoginForm, selectSignupForm } from 'app/containers/Auth/selectors';
import { actions } from 'app/containers/Auth/slice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FORGOT_TIMER } from 'utils/constants';

import { Button, Typography } from '@mui/material';

import { useStyles } from './style';

export default function Timer({formType, isRegister}: {formType?: "PHONE" | "EMAIL", isRegister?: boolean}) {
    const classes = useStyles()
    const { t } = useTranslation()

    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(true);
    const seconds = String(countDown % 60).padStart(2, '0');
    const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');
    const [resendCount, setResendCount] = useState(0);
    const loginForm = useSelector(selectLoginForm)
    const signupForm = useSelector(selectSignupForm)
    const dispatch = useDispatch()

    useEffect(() => {
      let timerId: any;
      if (runTimer) {
        setCountDown(FORGOT_TIMER);
        timerId = setInterval(() => {
          setCountDown(countDown => countDown - 1);
        }, 1000);
      } else {
        clearInterval(timerId);
      }
      return () => clearInterval(timerId);
    }, [runTimer]);

    useEffect(() => {
      if (countDown < 0 && runTimer) {
        setRunTimer(false);
        setCountDown(0);
        setIsResendDisabled(false);
      }
    }, [countDown, runTimer]);

    const onResend = ()=> {
        setResendCount(resendCount + 1);
        setRunTimer(true);
        setIsResendDisabled(true);
        if(isRegister && signupForm?.phoneNumber) {
          if(formType == 'PHONE' && signupForm?.phoneNumber){
            dispatch(actions.phoneNumberLogin({
              phoneNumber:signupForm?.phoneNumber
            }))
            return
          }
        }
        if(formType === 'EMAIL' && loginForm?.email){
          dispatch(actions.emailLogin({
            email:loginForm?.email
          }))
          return
        }

        if(formType == 'PHONE' && loginForm?.phoneNumber){
          dispatch(actions.phoneNumberLogin({
            phoneNumber:loginForm?.phoneNumber
          }))
          return
        }

        console.log('No method', {formType})
    }

    return (
        <Button
            onClick={onResend}
            disabled={runTimer}
            sx={{
                display: resendCount > 4 ? "none" : undefined
            }}
        >
            <Typography sx={runTimer || isResendDisabled ? {opacity: 0.6} : {}} className={classes.resend}>
                {runTimer ? `${minutes}:${seconds}` : t('AUTH.REQUEST_AGAIN')}
            </Typography>
        </Button>
    )
}
