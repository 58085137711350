import SignupForm from 'app/components/SignupForm';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { useLgStyles } from '../LoginPage/styles';
import OTP from '../OtpPage/form';
import { selectAuthRequestSuccess, selectInitialPage } from '../selectors';
import { actions } from '../slice';

function Signup() {
  const classes = useLgStyles();
  const dispatch = useDispatch()
  const isSuccessful = useSelector(selectAuthRequestSuccess)
  const [viewType, setViewType] = useState<"signup"|"otp">("signup")
  const initialPage = useSelector(selectInitialPage)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccessful) {
      dispatch(actions.setAuthRequestSuccess(false))
      setViewType("otp")
      if(initialPage && viewType === "otp"){
        navigate(`${initialPage}`)
      }
    }
  }, [isSuccessful, initialPage, viewType, dispatch])

  useEffect(()=>{
    dispatch(actions.getSettingConfig())
  },[dispatch])

  return (
    <Box className={classes.contentWrapper}>
      {
        viewType === "signup" &&
        <SignupForm />
      }
      {
        viewType === "otp" &&
        <OTP isRegister formType="PHONE"/>
      }
    </Box>
  );
}

export default Signup;
