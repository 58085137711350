import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import infoIcon from 'assets/ic_info.svg'
import info from 'assets/infoIcon.svg'
import imgplacehold from 'assets/ic_image-placeholder.svg';
import { Box, Typography, useTheme } from '@mui/material';
import useClickAwayListener from 'utils/hooks/useClickAwayListener';
import { useTranslation } from 'react-i18next';

export default function InfoCard({
    src
}:{
    src?: string
}) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    useClickAwayListener(dropdownRef, ()=>setIsOpen(false)); // Attach the hook to the ref
    const theme = useTheme()
    const { t } = useTranslation();
    return (
        <Box
            ref={dropdownRef}
        >
            {
                isOpen &&
                <Box
                    sx={{
                        position: "absolute",
                        right: 0,
                        left: 0,
                        top: 60,
                        height: "287px",
                        zIndex: 2,
                        borderRadius: "16px",
                        background: "#DDE9FF",
                        padding: "16px"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "16px",
                            cursor: "pointer"
                        }}
                        onClick={()=>setIsOpen(false)}
                    >
                        <img src={info} alt="info" />
                        <Typography
                            sx={{
                                color: "#C6DCEE",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400
                            }}
                        >
                            {t("AUTH.PAYER_NUMBER_INFO_TEXT")}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%"
                        }}
                    >
                        <img
                            src={src??imgplacehold}
                            width={src ? "90%" : "100px"}
                            height={src ? "220px" : "100px"}
                            alt="image_placeholder"
                            style={{
                                objectFit: "contain"
                            }}
                        />
                    </Box>
                </Box>
            }
            <Box
                sx={{
                    position: "absolute",
                    top: 10
                }}
                style={
                    theme.direction === "rtl" ? {
                        left: 0
                    } : {
                        right: 0
                    }
                }
            >
                <Button
                    sx={{
                        minWidth: 0
                    }}
                    onClick={()=>setIsOpen(prevState=>!prevState)}
                >
                    <img src={infoIcon} width="24px" height="24px" alt="info" />
                </Button>
            </Box>
        </Box>
    );
}
