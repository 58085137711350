import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: "#000",
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontWeight: 600,
        marginLeft: "16px"
    },
    input: {
        borderRadius: "10px",
        border: "0.5px solid #E0E0E0",
        fontFamily: "Roboto",
        fontSize: "20px",
        padding: "0 18px",
        fontWeight: 400,
        height: "100%",
    },
    error: {
        color: "#DD0000",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        marginLeft: "16px",
        marginTop: "6px"
    }
}));
