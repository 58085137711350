import { Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import CustomButton from "app/components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AllSet = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box
        className={classes.contentWrapper}
        sx={{
            justifyContent: "center"
        }}
    >

        <Box
            style={{
                minWidth: "454px",
                paddingTop: "15%"
            }}
        >
            <Typography
                className={classes.title}
            >
                {`${t("AUTH.YOU_ALL_SET_1")}`} <span style={{color:"#4596FF"}}>{`${t("AUTH.YOU_ALL_SET_2")}`}</span> {`${t("AUTH.YOU_ALL_SET_3")}`}
            </Typography>
            <Typography
                className={classes.supportText}
            >
                {t("AUTH.WELCOME_ALL_SET")}
            </Typography>
            <CustomButton
                btnStyle={{
                    marginTop: "32px"
                }}
                onClick={()=>navigate("/pages/terms-of-use")}
            >
            <Typography className={classes.btnText}>{t("BUTTONS.NEXT")}</Typography>
            </CustomButton>
        </Box>
    </Box>
  );
};

export default AllSet;
