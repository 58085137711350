import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import excel_ic from 'assets/excelIcon.svg'
import DateRangePicker from 'app/components/DateRangePicker'
import moment from 'moment'
import ExcelDialog from '../ExcelDialog'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'app/containers/Usage/slice'
import { dateFormat, getDate30DaysAgo, getDateDayAgo, numberWithCommas } from 'utils/constants'
import storage from 'utils/storage'
import { myMetaInfo, selectIsLoading, selectListData } from 'app/containers/Usage/selector'
import InfiniteScroll from 'react-infinite-scroll-component';

export default function UsageList() {
    const [dateRange, setDateRange] = useState<string[]>([dateFormat(getDate30DaysAgo()?.toDateString()), dateFormat(getDateDayAgo()?.toDateString())]);
    const [showDialog, setShowDialog] = useState(false)
    const dispatch = useDispatch();
    const listData = useSelector(selectListData)
    const metaInfo = useSelector(myMetaInfo);
    const isLoading = useSelector(selectIsLoading);
    const startDate = dateRange?.[0]
    const endDate = dateRange?.[1]

    const theme = useTheme();

    const handleDateSelection = (date:Date[]) => {
        const startDate = dateFormat(date[0].toDateString());
        const endDate = dateFormat(date[1].toDateString());
        setDateRange([startDate, endDate]);
    };

    useEffect(() => {
        storage.get("currentProfileId")
        .then((id)=>{
            if(startDate && endDate){
                dispatch(
                    actions.getListData({
                        isMore: true,
                        limit: 15,
                        page: 1,
                        id: id,
                        startDate: dateFormat(startDate, 'YYYY-MM-DD'),
                        endDate: dateFormat(endDate, 'YYYY-MM-DD'),
                    }),
                );
            }
        })
    }, [dispatch, startDate, endDate]);

    const onExport = async () => {
        const id = await storage.get("currentProfileId")
        const token = await storage.get("sessionToken")
        const url = `${process.env.REACT_APP_API_URL}usage/list/${id}/download?min=${startDate}&max=${endDate}&authToken=${token}`
        const win = window.open(url, '_blank');
        win?.focus();
    }


    const fetchMoreData = async () => {
        const id = await storage.get("currentProfileId")
        const params = {
            limit: 15,
            id,
            page: metaInfo.currentPage + 1,
            startDate: dateFormat(startDate as string, 'YYYY-MM-DD'),
            endDate: dateFormat(endDate as string, 'YYYY-MM-DD'),
        };
        dispatch(actions.getListData(params));
    };

    return (
        <Box
            style={{
                borderRadius: "20px",
                border: "3px solid #EFF0F6",
                background: "rgba(255, 255, 255, 0.80)",
                boxShadow: "0px 4px 4px 0px rgba(117, 117, 117, 0.10)",
                display: "flex",
                height: "1150px",
                flexDirection: "column",
                position: "relative",
                gap: "24px",
                padding: "20px 24px",
                direction: theme.direction,
                paddingBlock: "40px",
                overflow: "auto",
                minWidth: "400px"
            }}
            id="scrollTarget"
        >
            {
                isLoading &&
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <CircularProgress />
                </Box>
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
            >
                <Box
                    sx={{
                        cursor: "pointer",
                        width: "fit-content"
                    }}
                    onClick={()=>setShowDialog(true)}
                >
                    <img src={excel_ic} alt="excel" />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                {/* Calendar */}
                <DateRangePicker
                    startDate={startDate ? new Date(startDate) : null}
                    endDate={endDate ? new Date(endDate) : null}
                    handleDateSelection={handleDateSelection}
                />
                <Box
                    sx={{
                        padding: "8px 16px",
                        background: "#4596FF",
                        borderRadius: "200px"
                    }}
                >
                    <Typography
                        sx={{
                            color: "#FEFEFE",
                            fontFamily: "Roboto",
                            fontSize: "18px",
                            fontWeight: 600,
                        }}
                    >
                        {moment(startDate ?? new Date()).format('DD/MM/YY')} - {moment(endDate ?? new Date()).format('DD/MM/YY')}
                    </Typography>
                </Box>
            </Box>
            <InfiniteScroll
                dataLength={listData?.length}
                next={fetchMoreData}
                hasMore={(metaInfo?.currentPage < metaInfo?.totalPages)}
                loader={
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <CircularProgress
                            size={20}
                            color="primary"
                        />
                    </Box>
                }
                height={900}
                scrollableTarget="scrollTarget"
            >
                {
                    listData.map((item)=>{
                        return (
                            <Box
                                key={item?.layerDate}
                                sx={{
                                    borderRadius: "40px",
                                    border: "0.5px solid #E0E0E0",
                                    background: "#FFF",
                                    padding: "18px 50px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#1D1947",
                                        fontFamily: "Roboto",
                                        fontSize: "18px",
                                        fontWeight: 700,
                                    }}
                                >
                                    {dateFormat(item?.layerDate, 'DD/MM/YYYY')}
                                </Typography>
                                <Box
                                    sx={{
                                        color: "#4596FF",
                                        fontFamily: "Roboto",
                                        fontSize: "20px",
                                        fontWeight: 700,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        direction: 'ltr'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "20px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {`${item?.consumption?.toFixed(1)} `}
                                    </Typography>
                                    <small>m<sup>3</sup></small>
                                </Box>
                            </Box>
                        )
                    })
                }
            </InfiniteScroll>
            <ExcelDialog
                open={showDialog}
                handleClose={()=>setShowDialog(false)}
                onSend={onExport}
            />
        </Box>
    )
}
