import Layout from 'app/components/Layout';
import Notification from 'app/components/Notification';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'utils/storage';

import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { myMetaInfo, selectLoading, selectNotificationList } from './selector';
import { actions } from './slice';

export default function Notifications() {
    const notificationListData = useSelector(selectNotificationList);
    const dispatch = useDispatch();
    const metaInfo = useSelector(myMetaInfo);
    const isLoading = useSelector(selectLoading)
    const { t } = useTranslation();

    const theme = useTheme()

    useEffect(() => {
        storage.get('currentProfileId')
        .then((id)=>{
            if(id) {
                dispatch(actions.getNotificationData({
                    page: 1,
                    limit: 15,
                    id
                }));
            }
        })
    }, []);


    const fetchMoreData = () => {
        if(isLoading) return
        storage.get('currentProfileId')
        .then((id)=>{
            if(id) {
                dispatch(actions.getNotificationData({
                    limit: 15,
                    id,
                    page: metaInfo.currentPage + 1
                }));
            }
        })
    };

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                }}
                id="scrollTarget"
            >
                {
                    isLoading &&
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 3
                        }}
                    >
                        <CircularProgress
                            size={40}
                            color="primary"
                        />
                    </Box>
                }
                <InfiniteScroll
                    dataLength={notificationListData.length}
                    next={fetchMoreData}
                    hasMore={metaInfo?.currentPage < metaInfo?.totalPages}
                    loader={
                        <Box
                            key="loader"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px',
                            }}
                        >
                            <CircularProgress size={40} color="primary" />
                        </Box>
                    }
                    height={"85vh"}
                    scrollableTarget="scrollTarget"
                    style={{overflowX: 'hidden'}}
                >
                    {
                        notificationListData.length < 1 && !isLoading ?
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '80vh',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 34,
                                    fontWeight: '600',
                                    fill: '#DFDFDF',
                                    opacity: 0.5,
                                }}
                            >
                                {t('NOTIFICATIONS.NO_DATA')}
                            </Typography>
                        </Box> :
                        <Grid2 container spacing={2}>
                            {notificationListData.map((n) => (
                                <Grid2
                                    xs={6}
                                    key={n.id}
                                    sx={{
                                        direction: theme.direction,
                                    }}
                                >
                                    <Notification item={n} />
                                </Grid2>
                            ))}
                        </Grid2>
                    }
                </InfiniteScroll>
            </Box>
        </Layout>
    )
}
