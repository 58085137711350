import { ProfileData } from "../Settings/types";
import { UserResponse } from "../types";

/* --- STATE --- */
export interface DashboardState {
  message: string;
  loading: boolean;
  isAddingWaterId: boolean;
  error: any;
  dashboardData: DashboardData;
  monthlyBarChartData: BarGraphData[];
  yearlyBarChartData: BarGraphData[];
  monthlyData: GraphData[];
  AvgMonthlyConsumptionLast12Months: number;
  currentMonthForMonthly: string;
  currentMonthForYearly: string;
  meanForMonthly: number;
  meanForYearly: number;
  waterIdResponse: WaterIdResponse|null;
  isMonthSelected: boolean;
  addWaterIdError: any;
}

export interface MonthlyGraphDataResponse {
  type: string;
  mean: number;
  data: GraphData;
}

export interface GraphData {
  consumption: number;
  monthName: string;
}

export enum GraphTypeEnum {
  THIRTY_DAYS = 'THIRTY_DAYS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
}
export interface DashboardData {
  dailyReading: number;
  monthlyReading: number;
  lastDay: number;
  lastMonth: number;
  dailyAverage: number;
  monthlyAverage: number;
}

export interface GraphRequest {
  id?: string;
  type?: string;
  options?: string;
}

export interface WaterIdRequest {
  waterId: string;
}

export interface WaterIdResponse {
  message: string;
}

export interface BarGraphDataResponse {
  type: string;
  mean: number;
  data: BarGraphData[];
}

export interface BarGraphData {
  layerDate: string;
  consumption: number;
  lastDailyReading: number;
  readingDateTimeUtc: string;
  readingDateTimeSite: string;
  estimateFlag: number;
}
/*
    If you want to use 'ContainerState' keyword everywhere in your feature folder,
    instead of the 'AuthStateState' keyword.
  */
export type ContainerState = DashboardState;
