import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: "#AAACB7",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 400,
        textAlign: "left"
    },
    value: {
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: 500,
    }
}));
