import { Container, Box, CircularProgress } from '@mui/material'
import ActionButtons from 'app/components/ActionButtons'
import Layout from 'app/components/Layout'
import NotificationsForm from 'app/components/NotificationsForm'
import ProfilePhoto from 'app/components/ProfilePhoto'
import SettingsForm from 'app/components/SettingsForm'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsUpdated, selectLoading, selectProfileData } from './selector'
import { actions as authActions } from '../Auth/slice'
import { actions } from './slice'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { ProfileData } from './types'
import { selectCurrentProfile } from '../Auth/selectors'

export default function Settings() {
    const dispatch = useDispatch()
    const isLoading = useSelector(selectLoading)
    const isUpdated = useSelector(selectIsUpdated)
    const profileData = useSelector(selectCurrentProfile)
    const defaultProfileData = useSelector(selectProfileData)

    const validationSchema = Yup.object({
        email: Yup.string().email("AUTH.INVALID_EMAIL").nullable(),
    })

    const onSubmit = (values, { setSubmitting }:FormikHelpers<ProfileData>) => {
        dispatch(actions.updateProfileData(values))
        setSubmitting(false)
    }

    const memoizedProfileData = React.useMemo(()=>{
        return profileData
    },[profileData])

    useEffect(()=>{
        if(isUpdated){
            dispatch(authActions.getUser({}))
        }
    },[isUpdated, dispatch])

    return (
        <Layout>
            {
                isLoading &&
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 3
                    }}
                >
                    <CircularProgress
                        size={40}
                        color="primary"
                    />
                </Box>
            }
            <Formik
                initialValues={memoizedProfileData??defaultProfileData}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {
                    (Props) => (
                        <Form>
                            <Container maxWidth="md">
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <ProfilePhoto
                                        {...Props}
                                    />
                                </Box>
                                <SettingsForm
                                    {...Props}
                                />
                                <NotificationsForm
                                    {...Props}
                                />
                                <ActionButtons
                                    {...Props}
                                />
                            </Container>
                        </Form>
                    )
                }
            </Formik>
        </Layout>
    )
}
