import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Layout from 'app/components/Layout'
import React from 'react'
import UsageList from 'app/components/UsageList'
import DailyGraph from 'app/components/DailyGraph'
import WeeklyGraph from 'app/components/WeeklyGraph'
import MonthlyGraph from 'app/components/MonthlyGraph'

export default function Usage() {
    return (
        <Layout>
            <Grid2 spacing={"11px"} container>
                <Grid2 lg={8} md={7}>
                    <DailyGraph />
                    <WeeklyGraph />
                    <MonthlyGraph />
                </Grid2>
                <Grid2 lg={4} md={5}>
                    <UsageList />
                </Grid2>
            </Grid2>
        </Layout>
    )
}
