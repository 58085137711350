import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import Card from '../Card';
import ConsumptionCard from '../ConsumptionCard';

export default function Consumption({
    dashboardInfo
}) {
    const { t } = useTranslation()
    return (
        <Card
            style={{
                padding: "34px 30px",
                display: "flex",
                flexDirection: "column",
                //direction: theme.direction,
            }}
        >
            <Box borderBottom="0.75px solid rgba(233, 233, 234, 0.40)" display="flex" flexDirection="row">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    width="50%"
                    borderRight="0.75px solid rgba(233, 233, 234, 0.40)"
                    paddingBottom="25px"
                >
                    <ConsumptionCard
                        value={dashboardInfo?.lastDay}
                        label={t("DASHBOARD.LAST_DAY")}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    width="50%"
                    paddingBottom="25px"
                >
                    <ConsumptionCard value={dashboardInfo?.lastMonth} label={t("DASHBOARD.LAST_MONTH")} type='MONTHLY' />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    width="50%"
                    borderRight="0.75px solid rgba(233, 233, 234, 0.40)"
                    paddingTop="25px"
                >
                    <ConsumptionCard value={dashboardInfo?.dailyAverage} label={t("DASHBOARD.DAILY_AVERAGE")} />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    width="50%"
                    paddingTop="25px"
                >
                    <ConsumptionCard value={dashboardInfo?.monthlyAverage} label={t("DASHBOARD.MONTHLY_AVERAGE")} type='MONTHLY' />
                </Box>
            </Box>

        </Card>
    )
}
