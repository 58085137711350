import {createSlice} from 'utils//@reduxjs/toolkit';

import {PayloadAction} from '@reduxjs/toolkit';

import {ErrorType} from 'types/ErrorResponse';
import {
  ContainerState,
  Meta,
  NotificationResponse,
} from './types';
import { JSONValue } from 'utils/helpers';
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  loading: false,
  error: null,
  isMore: false,
  notificationList: [],
  meta: {} as Meta,
};

const notificationSlice = createSlice({
  name: 'notificationState',
  initialState,
  reducers: {
    getNotificationData(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    getNotificationDataSuccess(
      state,
      action: PayloadAction<NotificationResponse>,
    ) {
      state.loading = false;
      state.error = null;
      const items = action.payload.items.map(i => {
        return {
          ...i,
          params: JSONValue(i.data),
        };
      });
      state.notificationList = action.payload.meta.currentPage === 1 ? items :[...state.notificationList, ...items]
      state.meta = action.payload.meta;
    },

    getNotificationDataFailed(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.isMore = false;
      state.error = action.payload;
    },

    sendFcmToken(state, action: PayloadAction<any>) {},
  },
});

export const {actions, reducer, name: notificationSliceKey} = notificationSlice;
