import React, { useEffect, useMemo, useState } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { Logo } from '../Logo';
import { useStyles } from './styles';
import { styled } from '@mui/material/styles';
import { Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserProfiles } from 'app/containers/Dashboard/selector';
import storage from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from 'app/containers/Auth/slice';
import WaterIdForm from '../WaterIdForm';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { selectUser } from 'app/containers/Auth/selectors';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;

const settings = [
    "SETTINGS",
    "LOG_OUT"
]

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: "61px",
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  }),
}));

function Navbar() {
    const classes = useStyles()
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const userProfiles = useSelector(selectUserProfiles)
    const [currentProfile, setCurrentProfile] = React.useState(userProfiles?.[0])
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const user = useSelector(selectUser)

    const memoizedProfileData = useMemo(()=>userProfiles, [userProfiles])

    const { t } = useTranslation();

    useEffect(() => {
        storage.get('currentProfileId')
        .then((id)=>{
            if(id) {
                const profile = memoizedProfileData?.find((profile)=>profile?.id === id)
                setCurrentProfile(profile)
            }
        })
    }, [memoizedProfileData])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSelectWaterId = async (string) => {
        await storage.set('currentProfileId', string)
        window.location.reload()
    }

    const onLogout = async () => {
        await Promise.all([storage.clear("sessionToken"), storage.clear("currentProfileId")])
        dispatch(actions.resetUser())
        navigate("/auth/welcome")
    }

    return (
        <AppBar
            className={classes.container}
            position="fixed"
        >
            <Toolbar>
                <Logo
                    width="60px"
                    height="49.76px"
                />
                <Box sx={{flexGrow: 1}} />

                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip
                        title="Open settings"
                    >
                        <IconButton
                            onClick={handleOpenUserMenu}
                            sx={{ p: 0 }}
                            disableRipple

                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="24px"
                            >
                                <Box>
                                    <Typography className={classes.name}>{currentProfile?.appMeasureEntity?.displayConsumerName}</Typography>
                                    <Typography className={classes.address}>{currentProfile?.appMeasureEntity?.address}</Typography>
                                </Box>
                                <Avatar alt="Remy Sharp" src={currentProfile?.image?.url} />
                            </Box>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{
                            mt: '45px'
                        }}
                        PaperProps={{
                            sx: {
                                borderRadius: "22px"
                            }
                        }}
                        MenuListProps={{
                            sx: {
                                padding: 0
                            }
                        }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <Box
                                sx={{
                                    padding: "40px 24px 24px 48px",
                                    color: "#495057",
                                    fontFamily: "Open Sans",
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    lineHeight: "20px"
                                }}
                            >
                                <MenuItem>
                                    <Typography style={{color:"#4596FF"} }>{`${t(`AUTH.WATER_ID`)} - ${currentProfile?.appMeasureEntity?.device}`}</Typography>
                                </MenuItem>
                                <MenuItem>
                                    <Typography>{currentProfile?.appMeasureEntity?.customerName}</Typography>
                                </MenuItem>
                                {settings.map((setting, i) => (
                                    <MenuItem key={setting} onClick={i === 0 ? ()=>navigate("/settings") : i === 1 ? ()=>onLogout() : undefined}>
                                        <Typography>{t(`SETTINGS.${setting}`)}</Typography>
                                    </MenuItem>
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    padding: "40px 48px 24px 40px",
                                    background: "#F5FAFF",
                                    color: "#495057",
                                    fontFamily: "Open Sans",
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    lineHeight: "20px"
                                }}
                            >
                                {
                                    userProfiles?.map(u=>{
                                        return (
                                            <MenuItem onClick={()=>handleSelectWaterId(u?.id)} key={u?.id}>
                                                <Typography sx={u?.id===currentProfile?.id ? {color:"#4596FF"} : undefined}>{u?.appMeasureEntity?.device}</Typography>
                                            </MenuItem>
                                        )
                                    })
                                }

                                {user?.loginSource === 'PHONE' && (
                                    <Button
                                        onClick={()=>setOpen(true)}
                                        fullWidth
                                        sx={{
                                            color: "#4596FF"
                                        }}
                                    >
                                        <AddCircleIcon />
                                    </Button>
                                )}
                            </Box>
                        </Box>

                        <WaterIdForm
                            open={open}
                            handleClose={()=>setOpen(false)}
                        />
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default Navbar;