import React, { useRef } from 'react';
import { Box, Paper } from '@mui/material'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CustomButton from '../Button';
import dayjs from 'dayjs';
import { LooseValue } from 'react-calendar/dist/cjs/shared/types';
import useClickAwayListener from 'utils/hooks/useClickAwayListener';
import './style.css'
import { returnCalendarLocale } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

interface DateRangePickerProps {
    selected:LooseValue;
    handleDateSelection:(params)=>void;
    minDate?:Date;
    maxDate?:Date;
}

function SingleDatePicker({
    selected,
    handleDateSelection,
    minDate,
    maxDate
}:DateRangePickerProps) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const ref = useRef(null);
    useClickAwayListener(ref, () => setIsOpen(false));
    const { i18n } = useTranslation();

    return (
        <Box
            ref={ref}
        >
            <CustomButton
                btnStyle={{
                    borderRadius: "9px",
                    background: "#4596FF",
                    padding: "6px 16px",
                    color: "#FEFEFE",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 500,
                    height: "auto"
                }}
                onClick={()=>setIsOpen(!isOpen)}
            >
                {dayjs(selected as Date).format('DD MMM YYYY')}
            </CustomButton>

            <Paper
                sx={{
                position: 'absolute',
                top: '40px',
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: '#FFFFFF',
                zIndex: 1000,
                }}
            >
                {
                    isOpen &&
                    <Calendar
                        locale={returnCalendarLocale?.(i18n?.language)}
                        className='custom'
                        value={selected}
                        onChange={(value)=>{
                            handleDateSelection(value)
                            setIsOpen(false)
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                }
            </Paper>

        </Box>
    );
}

export default SingleDatePicker;
