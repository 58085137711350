import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useStyles } from './styles'
import dashboard_blue from "assets/dashboard-blue.svg";
import dashboard_white from 'assets/dashboard-white.svg';
import usage_blue from "assets/usage-blue.svg";
import usage_white from "assets/usage-white.svg";
import notify_white from "assets/notifications-white.svg";
import settings_white from "assets/setting-white.svg";
import notify_blue from "assets/notifications-blue.svg";
import settings_blue from "assets/setting-blue.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsDrawerOpen } from 'app/containers/Auth/selectors';
import { useDispatch } from 'react-redux';
import { actions } from 'app/containers/Auth/slice';

const drawerWidth = 240;

const MENU_LIST = [
  {
    name: "DASHBOARD",
    icon: dashboard_blue,
    openIcon: dashboard_white,
    path: "/"
  },
  {
    name: "USAGE",
    icon: usage_blue,
    openIcon: usage_white,
    path: "/usage"
  },
  {
    name: "NOTIFICATION",
    icon: notify_blue,
    openIcon: notify_white,
    path: "/notifications"
  },
  {
    name: "SETTINGS",
    icon: settings_blue,
    openIcon: settings_white,
    path: "/settings"
  }
]

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideDrawer() {
  const theme = useTheme();
  const classes = useStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const isDrawerOpen = useSelector(selectIsDrawerOpen)
  const dispatch = useDispatch()

  const returnCorrectIconType = (menu:{icon:string, openIcon:string}, active=false)=>{
    if(isDrawerOpen && active){
      return menu?.icon
    }
    if(isDrawerOpen && !active){
      return menu.openIcon
    }
    if(!isDrawerOpen && active){
      return menu.openIcon
    }
    if(!isDrawerOpen && !active){
      return menu.icon
    }
  }

  const genrateListItemStyle = (active:boolean)=>{
    if(isDrawerOpen && active){
      return {
        borderRadius: "20px",
        background: "#FFF",
        color: "#4596FF",
      }
    }
    if(!isDrawerOpen && active){
      return {
        background: "#4596FF"
      }
    }
  }

  return (
    <Drawer
      PaperProps={{
        sx: isDrawerOpen ? {
          backgroundColor: "#4596FF"
        } : undefined
      }}
      className={classes.container}
      variant="permanent"
      open={isDrawerOpen}
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
    >
        <DrawerHeader
          style={{marginTop: "61px"}}
        >
            <IconButton onClick={()=>dispatch(actions.toggleDrawer(!isDrawerOpen))}>
              {isDrawerOpen ?
                <ChevronLeftIcon
                  sx={{
                    color: "#fff",
                    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : undefined,
                  }}
                /> :
                <ChevronRightIcon
                  sx={{
                    color: "#4596FF",
                    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : undefined,
                  }}
                />
              }
            </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            {MENU_LIST.map((menu, index) => (
              <ListItem
                onClick={()=>navigate(menu.path)}
                key={menu?.name}
                disablePadding
                sx={{
                  display: 'block',
                  marginBottom: "26px",
                  paddingLeft: isDrawerOpen ? "14px" : 0,
                  paddingRight: isDrawerOpen ? "14px" : 0,
                  direction: theme.direction,
                }}
              >
                  <ListItemButton
                    sx={{
                        px: 2.5,
                        justifyContent: isDrawerOpen ? 'initial' : 'center',
                        color: "#FEFEFE",
                        gap: "10px",
                        ...genrateListItemStyle?.(pathname === menu.path),
                        '&:hover': {
                          color: "#fff !important",
                        },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: 'center'
                      }}
                    >
                      <img src={returnCorrectIconType?.(menu, pathname === menu.path)} alt={menu.name} />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.name}
                      primary={t(`BOTTOM_TAB.${menu.name}`)}
                      sx={{
                        opacity: isDrawerOpen ? 1 : 0,
                      }}
                      primaryTypographyProps={{
                        width: isDrawerOpen ? "fit-content" : 0,
                      }}
                    />
                  </ListItemButton>
              </ListItem>
            ))}
        </List>
    </Drawer>
  );
}