import {LoggerClass} from 'utils/logger';

const getFromStorage = async (key: any) => await localStorage.getItem(key);

const saveToStorage = async (key: any, value: any) => {
  try {
    return await localStorage.setItem(key, value);
  } catch (error) {
    LoggerClass.log('err async', error);
  }
  return null;
};
const removeToStorage = async (key: any) => await localStorage.removeItem(key);
const clearToStorage = async () => await localStorage.clear();

const storage = {
  get: (key: string) => getFromStorage(key),
  set: (key: string, value: any) => saveToStorage(key, value),
  clear: (key: string) => removeToStorage(key),
  remove: () => clearToStorage(),
};

export default storage;
