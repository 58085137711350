import React, { useState, useRef } from 'react';
import cal_ic from 'assets/calenderIcon.svg';
import { Box, useTheme } from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { LooseValue } from 'react-calendar/dist/cjs/shared/types';
import useClickAwayListener from 'utils/hooks/useClickAwayListener';
import { useTranslation } from 'react-i18next';
import { returnCalendarLocale } from 'utils/helpers';

interface DateRangePickerProps {
  startDate?: Date | null;
  endDate?: Date | null;
  handleDateSelection?: (dates: Date[]) => void;
}

function DateRangePicker({
  startDate,
  endDate,
  handleDateSelection,
}: DateRangePickerProps) {
  const [showCalendar, setShowCalendar] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickAwayListener(ref, () => setShowCalendar(false));
  const theme = useTheme();
  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        position: 'relative',
        width: "fit-content",
      }}
      ref={ref}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          left: theme?.direction === "rtl" ? undefined : '0px',
          right: theme?.direction === "rtl" ? '0px' : undefined,
          direction: "ltr",
          zIndex: 2
        }}
      >
        {
          showCalendar &&
          <Calendar
            locale={returnCalendarLocale?.(i18n.language)}
            value={[startDate, endDate] as LooseValue}
            onChange={(value)=>{
              handleDateSelection?.(value as Date[])
              setShowCalendar(false)
            }}
            minDate={moment().subtract(90, 'days').toDate()}
            maxDate={moment().subtract(1, 'days').toDate()}
            selectRange={true}
          />
        }
      </Box>
      <img
        src={cal_ic}
        alt="calendar"
        style={{
          cursor: 'pointer'
        }}
        onClick={() => setShowCalendar(!showCalendar)}
      />
    </Box>
  );
}

export default DateRangePicker;
