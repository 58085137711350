import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Typography, Input, Radio, useTheme } from '@mui/material';
import { useStyles } from './styles';

interface SelectInputProps {
    containerStyle?:React.CSSProperties;
    labelStyle:React.CSSProperties;
    label:string;
    inputStyle:React.CSSProperties;
    error?: string;
    value?: string | string[];
    placeholder?: string;
    options?: Options[];
    onChange?: (e:SelectChangeEvent)=>void
    disabled?: boolean;
}

interface Options {
    label: string;
    value: string;
}

export default function SelectInput({
    containerStyle,
    labelStyle,
    label,
    inputStyle,
    error,
    value,
    placeholder,
    options,
    onChange,
    disabled
}:SelectInputProps) {
  const classes = useStyles()
  const theme = useTheme()

  const errorStyle = () => {
      if(!error) return {}
      return {
          borderColor: "#DD0000"
      }
  }

  return (
    <Box
        style={containerStyle}
        sx={{
            direction: theme.direction
        }}
    >
        <Typography style={{
            ...labelStyle,
            marginBottom: "10px"
        }} className={classes.label}>{label}</Typography>
        <Select
            placeholder={placeholder}
            value={value as ""}
            onChange={onChange}
            input={<Input />}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: "200px",
                        borderWidth: 0.5,
                        borderColor: "#E0E0E0",
                        borderRadius: 16,
                        direction: theme.direction
                    }
                }
            }}
            disabled={disabled}
            renderValue={(selected) => <span style={{fontWeight:500}}>{options?.find(o=>o?.value === selected)?.label}</span>}
            fullWidth
            disableUnderline
            inputProps={{
                className: classes.input,
                sx: {
                    display: "flex",
                    alignItems: "center",
                    ...inputStyle,
                    ...errorStyle?.()
                },
            }}
            SelectDisplayProps={{
                style: {
                    fontWeight: 700,
                }
            }}
        >
            {options?.map((option) => (
                <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                        fontSize: 18,
                        fontWeight: '400',
                        color: "#050415",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <ListItemText primary={option?.label} />
                    <Radio
                        checked={value === option.value}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                            color: "#4596FF"
                          },
                        }}
                    />
                </MenuItem>
            ))}
        </Select>
    </Box>
  );
}