export const onValidateEmail = (email:string) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!email) {
    return "AUTH.INVALID_EMAIL";
  }
  if (!regex.test(email)) {
    return "AUTH.INVALID_EMAIL";
  }
}

export const onValidatePhoneNumber = (phoneNumber:string) => {
  const regex = /^(05)\d{8}$/
  if (!phoneNumber) {
    return "AUTH.ENTER_VALID_PHONE_NUMBER";
  }
  if (!regex.test(phoneNumber)) {
    return "AUTH.ENTER_VALID_PHONE_NUMBER";
  }
}