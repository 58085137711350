import { Box, Container, Checkbox, Typography, useTheme } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from "./styles";
import CustomButton from "app/components/Button";
import { Logo } from "app/components/Logo";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Auth/slice";
import { selectAuthRequestSuccess, selectLoading } from "app/containers/Auth/selectors";
import { TERMS_OF_USE } from "utils/constants";
import ConfirmDialog from "app/components/ConfirmDialog";
import { set } from "lodash";
import { LoadingIndicator } from "app/components/LoadingIndicator";

const TermsOfUse = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const classes = useStyles()
  const containerRef = useRef(null);
  const [disabled, setDisabled] = useState(true)
  const [agreed, setAgreed] = useState(false)
  const isLoading = useSelector(selectLoading)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSuccessful = useSelector(selectAuthRequestSuccess)
  const [showDialog, setShowDialog] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)


  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    console.log('Listener added')
    return () => window?.removeEventListener('message', receiveMessage);
  }, []);

  const receiveMessage = (event) => {
    console.log('Received message from iframe:', event)
    if(typeof event.data != 'string') { return }
    const data = JSON.parse(event.data)
    if(data.message === 'BOTTOM') {
      setDisabled(false)
    }

  };

  const onAgree = ()=> {
    dispatch(actions.checkAgreedToTerms(true))
  }

  useEffect(()=>{
    if(isSuccessful){
      dispatch(actions.setAuthRequestSuccess(false))
      navigate("/", {
        replace: true,
      })
    }
  },[isSuccessful])

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
      >
        <Logo width="120px" />
      </Box>
      <Container
        maxWidth={matches ? "lg" : "md"}
        sx={{
          direction: theme.direction
        }}
      >
        {
          !isLoaded && (
            <Box
              sx={{
                width: "100%",
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px"
              }}
            >
              <LoadingIndicator
                size={40}
                sx={{
                  color: "#4596FF"
                }}
              />
              <Typography
                className={classes.loadingText}
              >
                {t("AUTH.LOADING")}
              </Typography>
            </Box>
          )
        }
        <iframe
          onLoad={()=>setIsLoaded(true)}
          ref={containerRef}
          style={{
            width: "100%",
            height: "60vh",
            border: "none",

          }}
          src={TERMS_OF_USE}
        />
        <Box
          className={classes.row}
        >
          <Checkbox
            checked={agreed}
            onChange={()=>{
              if(disabled) {
                setShowDialog(true)
                return;
              };
              setAgreed(!agreed)
            }}
            sx={{
              color: "#4596FF",
              '&.Mui-checked': {
                color: "#4596FF",
              },
            }}
          />
          <Typography
            className={classes.label}
          >
            {t("AUTH.ACCEPT_TERMS_CONDITION")}
          </Typography>
        </Box>
        <Box
          className={classes.btnContainer}
        >
          <CustomButton
            onClick={onAgree}
            btnStyle={{
              width: "454px"
            }}
            isLoading={isLoading}
            disabled={!agreed || isLoading}
          >
            <Typography className={classes.btnText}>{t("BUTTONS.NEXT")}</Typography>
          </CustomButton>
        </Box>
      </Container>
      <ConfirmDialog
        open={showDialog}
        message={t("AUTH.TERMS_CONDITIONS_TEXT")}
        onSubmit={()=>setShowDialog(false)}
      />
    </Box>
  );
};

export default TermsOfUse;
