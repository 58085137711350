import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Input, Typography } from '@mui/material';
import closeIcon from 'assets/ic_close.svg'
import { useSelector, useDispatch } from 'react-redux';
import { selectAddWaterIdResponse, selectIsAddingWaterId, selectWaterIdErrorMessage } from 'app/containers/Dashboard/selector';
import { ENG_TO_HE } from 'utils/helpers';
import { actions } from 'app/containers/Dashboard/slice';
import CustomButton from '../Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface NotificationExceedingProps {
    open: boolean
    handleClose: ()=>void
}

export default function WaterIdForm({
    open,
    handleClose,
}:NotificationExceedingProps) {
    const error = useSelector(selectWaterIdErrorMessage)
    const isAddingWaterId = useSelector(selectIsAddingWaterId)
    const isSuccessful = useSelector(selectAddWaterIdResponse)
    const dispatch = useDispatch()
    const [value, setValue] = useState('')
    const { t } = useTranslation()

    const addWaterId = () => {
        dispatch(
            actions.addWaterId({
                waterId: value,
            }),
        );
    };

    useEffect(() => {
        if (isSuccessful) {
            window.location.reload()
        }
    }, [isSuccessful]);

    const onClose = () => {
        setValue('')
        dispatch(actions.resetAddWaterErrorMessage())
        handleClose()
    }

    const style = useStyles()
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: "20px",
                    border: "0.5px solid #FFF",
                    background: "#FFF",
                    padding: "16px 37px",
                    minWidth: "350px"
                }
            }}
        >
            <Button
                sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    backgroundColor: "rgba(69, 150, 255, 0.40)",
                    minWidth: "0px",
                    position: "absolute",
                    top: "16px",
                    right: "16px"
                }}
                onClick={onClose}
            >
                <img alt="close" src={closeIcon} width="16px" height="16px" />
            </Button>

            <Typography
                sx={{
                    color: "#050415",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: "16px",
                    marginBottom: "6px",
                    marginTop: "10px"
                }}
            >
                {t("AUTH.WATER_ID")}
            </Typography>
            <Input
                className={style.input}
                value={value}
                onChange={(e)=>{
                    if(e?.target?.value?.length < 26){
                        dispatch(actions.resetAddWaterErrorMessage())
                        setValue(e?.target?.value)
                    }
                }}
                style={{
                    border: `1px solid ${error ? "#FF0000" : "#E9E9EA"}`,
                }}
                disableUnderline
                placeholder={t('DASHBOARD.WATER_ID')}
            />
                {
                    error &&
                    <Typography
                        sx={{
                            color: "#FF0000",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            marginBottom: "6px",
                            marginTop: "6px"
                        }}
                    >
                        {ENG_TO_HE(`COMMON.${error}`)}
                    </Typography>
                }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: '16px',
                }}
            >
                <CustomButton
                    onClick={addWaterId}
                    disabled={isAddingWaterId || !value}
                    isLoading={isAddingWaterId}
                    btnStyle={{
                        borderRadius: "25px",
                        width: "fit-content",
                        height: "100%",
                        boxShadow: "0px 4px 10px 0px rgba(154, 166, 177, 0.30)",
                        fontFamily: "Roboto",
                        color: "#fff",
                        minWidth: "100px"
                    }}
                >
                    {t("BUTTONS.ADD")}
                </CustomButton>
            </Box>
        </Dialog>
    );
}