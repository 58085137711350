import { Box, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React, { ChangeEvent, useState, useEffect } from 'react'
import NotificationCard from '../NotificationCard'
import { useTranslation } from 'react-i18next'
import NotificationExceeding from '../NotificationExceeding'
import NotificationConsumption from '../NotificationConsumption'
import { FormikProps } from 'formik'
import { ProfileData } from 'app/containers/Settings/types'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectIsHiddenLeakageActive, selectIsRapidLeakageActive } from 'app/containers/Auth/selectors'

export default function NotificationsForm(Props:FormikProps<ProfileData>) {
    const { t } = useTranslation()
    const { values, setFieldValue } = Props
    const exceedVacation = values?.notificationVacationRequirementSettings
    const consumption = values?.notificationConsumptionSettings
    const rapidLeakage = values?.notificationRapidLeakageSettings
    const hiddenLeakage = values?.notificationHiddenLeakageSettings
    const [showExceedDialog, setShowExceedDialog] = useState(false)
    const [showConsumptionDialog, setShowConsumptionDialog] = useState(false)
    const isHiddenLeakageActive = useSelector(selectIsHiddenLeakageActive)
    const isRapidLeakageActive = useSelector(selectIsRapidLeakageActive)
    const theme = useTheme()

    useEffect(() => {
        if(!values?.email){
            setFieldValue("notificationVacationRequirementSettings.isEmailEnabled", false)
            setFieldValue("notificationConsumptionSettings.isEmailEnabled", false)
            setFieldValue("notificationRapidLeakageSettings.isEmailEnabled", false)
            setFieldValue("notificationHiddenLeakageSettings.isEmailEnabled", false)
        }
    }, [values?.email, setFieldValue])

    useEffect(()=>{
        if(!rapidLeakage?.isEnabled){
            setFieldValue("notificationRapidLeakageSettings.isEnabled", true)
        }
        if(!hiddenLeakage?.isEnabled){
            setFieldValue("notificationHiddenLeakageSettings.isEnabled", true)
        }
    },[rapidLeakage?.isEnabled, hiddenLeakage?.isEnabled])

    return (
        <Box
            sx={{
                paddingBottom: "56px",
                direction: theme.direction
            }}
        >
            <Typography
                sx={{
                    color: "#000",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: 800,
                    marginBottom: "16px"
                }}
            >
                {t("SETTINGS.NOTIFICATION")}
            </Typography>

            <Grid2 spacing={"24px"} container>
                <Grid2 xs={6}>
                    <NotificationCard
                        title={t("SETTINGS.EXCEEDING_REQUIREMENT_ON_VACATION")}
                        subText={exceedVacation?.threshold && t('SETTINGS.VACATION_CAPTION', {limit:exceedVacation?.threshold, startDate:dayjs(exceedVacation?.vacationStartAt).format('DD/MM/YYYY'), endDate:dayjs(exceedVacation?.vacationEndAt).format('DD/MM/YYYY')})}
                        onActivate={(e:ChangeEvent<any>)=>{
                            if(!e.target.checked){
                                setFieldValue("notificationVacationRequirementSettings.isEnabled", e.target.checked)
                                setFieldValue("notificationVacationRequirementSettings.isEmailEnabled", e.target.checked)
                                setFieldValue("notificationVacationRequirementSettings.isPushEnabled", e.target.checked)
                                setFieldValue("notificationVacationRequirementSettings.isSMSEnabled", e.target.checked)
                                return
                            }
                            setFieldValue("notificationVacationRequirementSettings.isEnabled", e.target.checked)
                            setShowExceedDialog(true)
                        }}
                        isEnable={exceedVacation?.isEnabled}
                        isControlled={true}
                        toggleEmail={(e:ChangeEvent<any>)=>{
                            setFieldValue("notificationVacationRequirementSettings.isEmailEnabled", e.target.checked)
                        }}
                        togglePush={(e:ChangeEvent<any>)=>{
                            setFieldValue("notificationVacationRequirementSettings.isPushEnabled", e.target.checked)
                        }}
                        toggleSMS={(e:ChangeEvent<any>)=>{
                            setFieldValue("notificationVacationRequirementSettings.isSMSEnabled", e.target.checked)
                        }}
                        isEmail={exceedVacation?.isEmailEnabled}
                        isPush={exceedVacation?.isPushEnabled}
                        isSMS={exceedVacation?.isSMSEnabled}
                        emailDisabled={!!!values?.email}
                    />
                </Grid2>
                <Grid2 xs={6}>
                    <NotificationCard
                        title={t("SETTINGS.CONSUMPTION_THRESHOLD_EXCEEDED")}
                        subText={consumption?.threshold && t('SETTINGS.CONSUMPTION_LIMIT', {limit:consumption?.threshold})}
                        showInfoIcon={false}
                        onActivate={(e:ChangeEvent<any>)=>{
                            if(!e.target.checked){
                                setFieldValue("notificationConsumptionSettings.isEnabled", e.target.checked)
                                setFieldValue("notificationConsumptionSettings.isEmailEnabled", e.target.checked)
                                setFieldValue("notificationConsumptionSettings.isPushEnabled", e.target.checked)
                                setFieldValue("notificationConsumptionSettings.isSMSEnabled", e.target.checked)
                                return
                            }
                            setFieldValue("notificationConsumptionSettings.isEnabled", e.target.checked)
                            setShowConsumptionDialog(true)
                        }}
                        isControlled={true}
                        isEnable={consumption?.isEnabled}
                        toggleEmail={(e:ChangeEvent<any>)=>{
                            setFieldValue("notificationConsumptionSettings.isEmailEnabled", e.target.checked)
                        }}
                        togglePush={(e:ChangeEvent<any>)=>{
                            setFieldValue("notificationConsumptionSettings.isPushEnabled", e.target.checked)
                        }}
                        toggleSMS={(e:ChangeEvent<any>)=>{
                            setFieldValue("notificationConsumptionSettings.isSMSEnabled", e.target.checked)
                        }}
                        isEmail={consumption?.isEmailEnabled}
                        isPush={consumption?.isPushEnabled}
                        isSMS={consumption?.isSMSEnabled}
                        emailDisabled={!!!values?.email}
                    />
                </Grid2>
                {
                    isRapidLeakageActive &&
                    <Grid2 xs={6}>
                        <NotificationCard
                            title={t("SETTINGS.SUSPECTED_RAPID_LEAKAGE")}
                            subText={t('SETTINGS.SUSPECTED_RAPID_LEAKAGE_SUBTEXT')}
                            showInfoIcon={false}
                            isEnable={rapidLeakage?.isEnabled}
                            toggleEmail={(e:ChangeEvent<any>)=>{
                                setFieldValue("notificationRapidLeakageSettings.isEmailEnabled", e.target.checked)
                            }}
                            togglePush={(e:ChangeEvent<any>)=>{
                                setFieldValue("notificationRapidLeakageSettings.isPushEnabled", e.target.checked)
                            }}
                            toggleSMS={(e:ChangeEvent<any>)=>{
                                setFieldValue("notificationRapidLeakageSettings.isSMSEnabled", e.target.checked)
                            }}
                            isEmail={rapidLeakage?.isEmailEnabled}
                            isPush={rapidLeakage?.isPushEnabled}
                            isSMS={rapidLeakage?.isSMSEnabled}
                            emailDisabled={!!!values?.email}
                        />
                    </Grid2>
                }
                {
                    isHiddenLeakageActive &&
                    <Grid2 xs={6}>
                        <NotificationCard
                            title={t("SETTINGS.SUSPECTED_HIDDEN_LEAKAGE")}
                            subText={t('SETTINGS.SUSPECTED_HIDDEN_LEAKAGE_SUBTEXT')}
                            showInfoIcon={false}
                            isEnable={hiddenLeakage?.isEnabled}
                            toggleEmail={(e:ChangeEvent<any>)=>{
                                setFieldValue("notificationHiddenLeakageSettings.isEmailEnabled", e.target.checked)
                            }}
                            togglePush={(e:ChangeEvent<any>)=>{
                                setFieldValue("notificationHiddenLeakageSettings.isPushEnabled", e.target.checked)
                            }}
                            toggleSMS={(e:ChangeEvent<any>)=>{
                                setFieldValue("notificationHiddenLeakageSettings.isSMSEnabled", e.target.checked)
                            }}
                            isEmail={hiddenLeakage?.isEmailEnabled}
                            isPush={hiddenLeakage?.isPushEnabled}
                            isSMS={hiddenLeakage?.isSMSEnabled}
                            emailDisabled={!!!values?.email}
                        />
                    </Grid2>
                }
            </Grid2>
            <NotificationExceeding
                open={exceedVacation?.isEnabled && showExceedDialog}
                handleClose={()=>{
                    setFieldValue("notificationVacationRequirementSettings.isEnabled", false)
                    setShowExceedDialog(false)
                }}
                onSubmit={(values)=>{
                    setFieldValue("notificationVacationRequirementSettings.isEnabled", true)
                    setFieldValue("notificationVacationRequirementSettings.threshold", values.limit)
                    setFieldValue("notificationVacationRequirementSettings.vacationEndAt", values.toDate)
                    setFieldValue("notificationVacationRequirementSettings.vacationStartAt", values.fromDate)
                    setShowExceedDialog(false)
                }}
            />
            <NotificationConsumption
                open={consumption?.isEnabled && showConsumptionDialog}
                handleClose={()=>{
                    setFieldValue("notificationConsumptionSettings.isEnabled", false)
                    setShowConsumptionDialog(false)
                }}
                onSubmit={(values)=>{
                    setFieldValue("notificationConsumptionSettings.isEnabled", true)
                    setFieldValue("notificationConsumptionSettings.threshold", values.value)
                    setShowConsumptionDialog(false)
                }}
            />
        </Box>
    )
}
