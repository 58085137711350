/**
 * Homepage selectors
 */

import {RootState} from '../../../types';

import {createSelector} from '@reduxjs/toolkit';
import {initialState} from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.settingsState || initialState;

export const selectLoading = createSelector([selectDomain], state => state.loading);

export const selectProfileData = createSelector(
  [selectDomain],
  state => state.profileData,
);

export const selectUploadedImage = createSelector(
  [selectDomain],
  state => state.uploadedImage,
);

export const selectIsUploading = createSelector(
  [selectDomain],
  state => state.isUploading,
);

export const selectIsSaving = createSelector(
  [selectDomain],
  state => state.isSaving,
);

export const selectError = createSelector([selectDomain], state => state.error);

export const selectIsUpdated = createSelector([selectDomain], state => state.updated);
